import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSocketInstance } from '../../../App';
import Button from '../../../shared/components/Button/Button';
import AngleLeftIcon from '../../../shared/components/Icons/AngleLeftIcon';
import InfoIcon from '../../../shared/components/Icons/InfoIcon';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import { convertHexToRGBA } from '../../../shared/utils/colors';
import { getCompanyBrandCustomizations, getJobPostData } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import { getGuestInvited } from '../../../store/ui/selectors';

export const GuestInvited = () => {
  // Hooks
  const { t } = useTranslation();
  const { socket } = useSocketInstance();

  // Selectors
  const { email, name, surname } = useAppSelector(getGuestInvited);
  const jobPost = useAppSelector(getJobPostData);
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  // Computed
  const coverBackground = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 25);

  // Handler
  const goBackHandler = (): void => {
    window.location.href = window.location.href.replace('/invited', '');
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (socket) {
      timeout = setTimeout(() => {
        socket.disconnect();
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [socket]);

  if (!jobPost) {
    return null;
  }

  return (
    <div className={clsx('flex h-full w-full')}>
      <div
        className={clsx(
          'mx-auto flex h-full w-full max-w-[1600px] flex-col p-4',
          'lg:max-h-[980px] lg:flex-row-reverse lg:items-stretch lg:gap-32 lg:p-16'
        )}
      >
        {/* Cover section */}
        <div className={clsx('mb-4 flex w-full', 'lg:w-auto lg:flex-1')}>
          <div
            className={clsx(
              'flex h-[240px] w-full items-center justify-center rounded-2xl bg-cover bg-top bg-no-repeat',
              'lg:flex lg:h-full'
            )}
            style={{ backgroundColor: coverBackground }}
          >
            <img
              src="/assets/email.png"
              alt="SkillVue - Immagine email con notifica"
              className="mx-auto block h-auto max-h-full max-w-full"
            />
          </div>
        </div>

        {/* Copy section */}
        <div className={clsx('flex w-full', 'lg:w-auto lg:flex-1 lg:flex-col')}>
          <div className="lg:max-w-[480px]">
            <JobHeader />

            <div
              className={clsx(
                'mt-5 flex w-full flex-col items-start justify-stretch px-4',
                'lg:px-0'
              )}
            >
              <h3 className="mb-6 text-2xl font-bold text-[#09090B]">
                {t('guest.invited.header')}
              </h3>
              <p>
                {t('guest.invited.subheader')}{' '}
                <b>
                  {name} {surname}
                </b>
              </p>
              <div className="my-6">
                {t('guest.invited.email_sent')} <br />
                <b>{email}</b>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('guest.invited.email_info1', { jobTitle: jobPost.name }),
                }}
              />
              <p className="mt-6">{t('guest.invited.email_info2')}</p>
              <div className="mt-6 flex items-center justify-start">
                <InfoIcon color="#71717A" />
                <p className="ml-2 flex-1 text-sm text-[#71717A]">{t('guest.invited.spam')}</p>
              </div>
            </div>

            <div
              className={clsx(
                'border-top fixed bottom-0 left-0 right-0 border-t-[1px] border-[#DDDDDD] bg-white p-4',
                'sm:static sm:mt-10 sm:border-0 sm:bg-transparent sm:p-0'
              )}
            >
              <Button
                className={clsx('secondary alt mx-auto !w-full !rounded-lg', 'sm:mx-0 sm:!w-auto')}
                onClick={goBackHandler}
              >
                <AngleLeftIcon />
                <span className="ml-1">{t('guest.invited.back')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
