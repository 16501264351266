import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import TriangleExclamationIcon from '../../shared/components/Icons/TriangleExclamationIcon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  message: string;
}

const ErrorBox = ({ visible, message, ...props }: Props) => {
  if (!visible) return null;

  return (
    <div
      className={clsx(
        'flex items-start justify-start rounded-lg bg-[#B41D08] p-2',
        props.className
      )}
    >
      <div className="mr-2 h-[25px] w-[25px]">
        <TriangleExclamationIcon color="#fff" />
      </div>
      <p className="text-sm font-semibold leading-5 text-white">{message}</p>
    </div>
  );
};

export default ErrorBox;
