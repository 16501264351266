import clsx from 'clsx';
import { HTMLAttributes, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {}

const Card = ({ children, ...props }: Props) => {
  return (
    <div
      className={clsx(
        'mx-auto flex h-full w-full rounded-2xl bg-white shadow-card',
        'md:p-6',
        props.className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
