import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import { CustomQuestionnaireQuestionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import AngleLeftIcon from '../../../shared/components/Icons/AngleLeftIcon';
import AngleRightIcon from '../../../shared/components/Icons/AngleRightIcon';
import ClockIcon from '../../../shared/components/Icons/ClockIcon';
import InfoIcon from '../../../shared/components/Icons/InfoIcon';
import { actions as examActions } from '../../../store/exam/reducer';
import {
  getCompanyBrandCustomizations,
  getCurrentCustomQuestionnaireQuestionCounters,
  getCurrentStep,
  getCustomQuestionnaireGlobalTimer,
  getCustomQuestionnaireUserAnswers,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import AnswerText from '../../Questionnaire/components/AnswerText';
import QuestionText from './QuestionText';

const HURRY_UP_THRESHOLD = 180;

const CustomQuestionnaire = () => {
  const { t } = useTranslation();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const navigate = useNavigate();

  const { socket } = useSocketInstance();

  const dispatch = useAppDispatch();

  const currentStep = useAppSelector(getCurrentStep) as CustomQuestionnaireQuestionStep | null;
  const selectedAnswers = useAppSelector(getCustomQuestionnaireUserAnswers);
  const globalTimer = useAppSelector(getCustomQuestionnaireGlobalTimer);
  const counters = useAppSelector(getCurrentCustomQuestionnaireQuestionCounters);
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  const [timer, setTimer] = useState<string | null>(null);
  const [expiring, setExpiring] = useState<boolean>(false);

  // Use this for the time
  /* const progress =
    currentStep && ((currentStep.data.duration - globalTimer) / currentStep.data.duration) * 100; */

  // Use this for the questions counter
  const progress = counters && (counters.current / counters.total) * 100;

  const selectAnswerHandler = (id: number) => () => {
    if (!selectedAnswers.includes(id)) {
      dispatch(examActions.STORE_CUSTOM_QUESTIONNAIRE_QUESTION_ANSWER({ answer_id: id }));
    }
  };

  const goToPreviousStepHandler = (): void => {
    socket.emit('custom-questionnaire-prev', {
      id: currentStep?.data.questionnaire_id,
      user_answers: selectedAnswers,
    });
    dispatch(examActions.GET_PREVIOUS_STEP());
  };

  const goToNextStepHandler = (): void => {
    socket.emit('custom-questionnaire-next', {
      id: currentStep?.data.questionnaire_id,
      user_answers: selectedAnswers,
    });
    dispatch(examActions.SET_STEP_COMPLETED()); // Even if the answer is empty it should be marked as completed
    dispatch(examActions.GET_NEXT_STEP());
  };

  const submitHandler = (): void => {
    socket.emit('custom-questionnaire-complete', {
      id: currentStep?.data.questionnaire_id,
      user_answers: selectedAnswers,
    });
    dispatch(examActions.SET_STEP_COMPLETED()); // Even if the answer is empty it should be marked as completed
    navigate(`/${job_post_alias}/${application_alias}/custom-questionnaire-done`, {
      replace: true,
    });
  };

  // Handle the questionnaire timer tick interval
  useEffect(() => {
    if (currentStep && globalTimer) {
      const remainingTimeMs = currentStep.data.duration * 1000 - globalTimer * 1000;
      if (remainingTimeMs >= 0) {
        const hours = Math.floor((remainingTimeMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remainingTimeMs / (1000 * 60)) % 60);
        const seconds = Math.floor((remainingTimeMs / 1000) % 60);

        const formattedTime =
          (hours > 0 ? (hours < 10 ? '0' + hours : hours) + ':' : '') +
          (minutes < 10 ? '0' + minutes : minutes) +
          ':' +
          (seconds < 10 ? '0' + seconds : seconds);

        setTimer(formattedTime);
      } else {
        setTimer('00:00');
      }

      setExpiring(currentStep.data.duration - globalTimer < HURRY_UP_THRESHOLD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTimer]);

  useEffect(() => {
    const expirationHandler = (): void => {
      submitHandler();
      clearInterval(interval);
    };

    let interval: ReturnType<typeof setInterval> | undefined;

    if (!interval && currentStep) {
      interval = setInterval(() => {
        socket.emit('custom-questionnaire-tick', {
          custom_questionnaire_id: currentStep.data.questionnaire_id,
          user_answers: selectedAnswers,
        });
        // The questionnaire may not have a duration.
        if (currentStep.data.duration) {
          dispatch(examActions.UPDATE_CUSTOM_QUESTIONNAIRE_TIMER());
        }
      }, 1000);
      socket.on('custom-questionnaire-time-expired', expirationHandler);
    }

    return () => {
      clearInterval(interval);
      socket.off('custom-questionnaire-time-expired', expirationHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentStep || currentStep.type !== 'custom-questionnaire-question') {
    return null;
  }

  return (
    <div className={clsx('relative flex h-full w-full flex-col')}>
      {/* Sticky top bar */}
      <div
        className={clsx(
          'sticky top-0 flex w-full flex-col bg-white',
          'lg:flex-row lg:items-center lg:px-6 lg:py-4'
        )}
      >
        {/* Custom questionnaire's title + Timer + Progress bar */}
        <div className="flex flex-1 flex-col px-6 py-4">
          <div
            className={clsx(
              'flex h-16 w-full items-center justify-start',
              'lg:h-auto lg:px-0 lg:py-4'
            )}
          >
            <div className="flex-1 overflow-hidden">
              <HeadingText
                variant="primary-small"
                className="truncate text-xl"
              >
                {currentStep.data.questionnaire_name}
              </HeadingText>
            </div>
            {/** Timer */}
            {currentStep.data.duration && (
              <div
                className={clsx(
                  'flex items-center justify-center gap-2 rounded-full text-xl font-bold tabular-nums',
                  {
                    'text-black': !expiring,
                    'text-[#ed4545]': expiring,
                  }
                )}
              >
                <ClockIcon color={!expiring ? '#000' : '#ed4545'} />
                <span>{timer}</span>
              </div>
            )}
          </div>
          {/* Progress bar */}
          <div className="relative h-[2px] w-full bg-gray-300">
            <div
              className={clsx('transition-width absolute left-0 top-0 h-full duration-500', {
                'bg-blue-500': !brandCustomization?.['accent-color'],
                'bg-[var(--accent-color)]': brandCustomization?.['accent-color'],
              })}
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
        {/* Buttons visible only on desktop mode*/}
        <div className="hidden lg:flex lg:gap-6">
          <Button
            className="secondary h-10 min-w-32 px-4 py-2"
            disabled={counters && counters.current + 1 === 1}
            onClick={goToPreviousStepHandler}
          >
            <AngleLeftIcon
              width={16}
              className="mr-1"
            />
            {t('customQuestionnaire.button.back')}
          </Button>
          <Button
            className="default h-10 min-w-32 px-4 py-2"
            onClick={
              counters && counters.current + 1 === counters.total
                ? submitHandler
                : goToNextStepHandler
            }
          >
            {counters &&
              counters.current + 1 === counters.total &&
              t('customQuestionnaire.button.complete')}
            {counters && counters.current + 1 < counters.total && (
              <span className="flex w-full items-center justify-center">
                {t('customQuestionnaire.button.next')}
                <AngleRightIcon
                  className="ml-1"
                  width={16}
                  color="#FFF"
                />
              </span>
            )}
          </Button>
        </div>
      </div>

      {/* Middle container */}
      <div
        className={clsx(
          'flex flex-1 flex-col overflow-y-auto bg-white px-4',
          'lg:flex lg:flex-row lg:overflow-y-hidden lg:px-6'
        )}
      >
        <div className="lg:flex-1 lg:flex-col lg:p-4">
          {/* Questions counters */}
          <HeadingText
            className="mt-4 text-base font-bold lg:text-lg"
            variant="secondary"
          >
            {t('customQuestionnaire.question.title', {
              current: counters && counters.current + 1,
              total: counters && counters.total,
            })}
          </HeadingText>

          {/* Question container */}
          <div className={clsx('pt-4', 'lg:mb-0 lg:mr-4 lg:h-full lg:flex-1 lg:overflow-y-auto')}>
            <QuestionText
              className="no-clipboard question-text text-base font-semibold text-[#101828] lg:h-full lg:text-xl"
              description={currentStep.data.question.text}
            />
          </div>
        </div>

        <div className="lg:flex lg:flex-1 lg:flex-col lg:p-4">
          {/* Info Box */}
          <div className="my-5 flex gap-2 text-[#666]">
            <InfoIcon color="#666" /> <span>{t('customQuestionnaire.infobox.text')}</span>
          </div>
          {/* Answers container */}
          <div className={clsx('lg:ml-4 lg:flex-1 lg:overflow-y-auto')}>
            {currentStep.data.answers.map((answer) => (
              <AnswerText
                key={`question-${currentStep.data.question.id}-answer-${answer.id}`}
                classes="flex-1 font-medium font-[#101828] question-answer overflow-x-auto"
                selected={answer.id === currentStep.user_data.answer_id}
                description={answer.text}
                onChange={selectAnswerHandler(answer.id)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Sticky bottom bar */}
      <div
        className={clsx(
          'sticky bottom-0 left-0 z-10 flex w-full items-center justify-between border-t border-solid border-[#d0d5dd] bg-white p-4',
          'lg:hidden'
        )}
      >
        <Button
          className="secondary mr-4"
          disabled={counters && counters.current + 1 === 1}
          onClick={goToPreviousStepHandler}
        >
          <AngleLeftIcon
            width={16}
            className="mr-1"
          />
          {t('customQuestionnaire.button.back')}
        </Button>
        <Button
          className="default ml-1"
          onClick={
            counters && counters.current + 1 === counters.total
              ? submitHandler
              : goToNextStepHandler
          }
        >
          {counters &&
            counters.current + 1 === counters.total &&
            t('customQuestionnaire.button.complete')}
          {counters && counters.current + 1 < counters.total && (
            <>
              {t('customQuestionnaire.button.next')}
              <AngleRightIcon
                className="ml-1"
                width={16}
                color="#FFF"
              />
            </>
          )}
        </Button>
      </div>
      {/* Warning box */}
      <div className={clsx('flex justify-center bg-[#FF9A24] p-4 font-semibold text-[#09090B]')}>
        {t('questionnaire.warning.text')}
      </div>
    </div>
  );
};

export default CustomQuestionnaire;
