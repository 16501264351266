const SUPPORT_EMAIL = import.meta.env.VITE_APP_SUPPORT_EMAIL;

interface Props {
  link?: string;
}

export const SupportEmail = ({ link }: Props) => {
  const internalLink: string = link ?? encodeURI(`mailto:${SUPPORT_EMAIL}`);
  return (
    <a
      href={internalLink}
      target="_blank"
      rel="noreferrer"
      className="font-normal text-[#0078d6] underline"
    >
      {SUPPORT_EMAIL}
    </a>
  );
};
