import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const ExamExpiredIcon = ({ color, ...props }: Props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_862_36361)">
        <path
          d="M16 0C7.162 0 0 7.162 0 16c0 8.837 7.162 16 16 16 8.837 0 16-7.163 16-16 0-8.838-7.163-16-16-16zm0 29C8.831 29 3 23.168 3 16S8.831 3 16 3s13 5.832 13 13-5.831 13-13 13zm2.5-8h-1v-5.5A1.5 1.5 0 0016 14h-2c-.825 0-1.5.675-1.5 1.5S13.175 17 14 17h.5v4h-1c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5h5a1.5 1.5 0 000-3zM16 12a2 2 0 100-4 2 2 0 000 4z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_862_36361">
          <path
            fill="#fff"
            d="M0 0H32V32H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExamExpiredIcon;
