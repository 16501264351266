import { SVGAttributes } from 'react';

export default function AngleDownIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      {...props}
      className={props.className?.concat(' arrow-icon')}
    >
      <path
        fill={props.color ?? '#000'}
        d="M13.354 15.854a.934.934 0 0 1-1.325 0l-6.254-6.25A.937.937 0 0 1 7.1 8.28l5.586 5.585 5.59-5.59A.937.937 0 0 1 19.6 9.6l-6.245 6.255Z"
      />
    </svg>
  );
}
