import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TriangleRedExclamationIcon from '../Icons/TriangleRedExclamationIcon';
import WiFiBad from '../Icons/WiFiBad';
import WiFiGood from '../Icons/WiFiGood';

interface Props {
  type: 'banner' | 'icon';
  connected: boolean;
}

const ConnectionStatus = ({ type, connected }: Props) => {
  const { t } = useTranslation();

  if (type === 'icon') {
    return (
      <div
        className={clsx('z-20 flex h-6 w-6 items-center justify-center rounded-full', {
          'bg-[#DAFBE1]': connected,
          'bg-[#FFEBE9]': !connected,
        })}
      >
        {connected && (
          <WiFiGood
            width={16}
            height={16}
            color="#18682F"
          />
        )}
        {!connected && (
          <WiFiBad
            width={16}
            height={16}
            color="#A21B24"
          />
        )}
      </div>
    );
  }

  if (type === 'banner' && connected) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex w-full items-center justify-center bg-[#DF1B41] px-4 py-2 text-center',
        'lg:mb-2 lg:justify-start lg:rounded-lg lg:bg-white lg:text-left lg:shadow-cust-1'
      )}
    >
      <div
        className={clsx(
          'mr-4 hidden h-[44px] w-[44px] flex-col items-center justify-center rounded-full bg-[#ED4545]',
          'lg:flex'
        )}
      >
        <TriangleRedExclamationIcon />
      </div>
      <p className={clsx('text-xs text-white', 'lg:text-base lg:font-semibold lg:text-[#101828]')}>
        {t('videoInterview.disconnection.banner')}
      </p>
    </div>
  );
};

export default ConnectionStatus;
