import { SVGAttributes } from 'react';

export default function VideoInterviewIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={48}
        height={48}
        rx={24}
        fill="#6B52FF"
        fillOpacity={0.2}
      />
      <path
        d="M33.6 14.25H14.4a2.38 2.38 0 00-2.388 2.368L12 35.06c0 1.054 1.296 1.587 2.052.84l2.748-2.71h16.8c1.32 0 2.4-1.065 2.4-2.368V16.618c0-1.303-1.08-2.368-2.4-2.368z"
        fill="#6B52FF"
      />
      <g filter="url(#filter0_d_533_1810)">
        <path
          d="M27.6 24.512l1.38 1.425c.372.4 1.02.113 1.02-.45V21c0-.55-.648-.837-1.02-.438l-1.38 1.425V19.5c0-.688-.54-1.25-1.2-1.25h-7.2c-.66 0-1.2.563-1.2 1.25V27c0 .688.54 1.25 1.2 1.25h7.2c.66 0 1.2-.563 1.2-1.25v-2.488z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_533_1810"
          x={18}
          y={18.25}
          width={12.6667}
          height={10.6667}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.333333}
            dy={0.333333}
          />
          <feGaussianBlur stdDeviation={0.166667} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_533_1810"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_533_1810"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
