import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const MicrophoneIcon = ({ color = '#525252', ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill={'none'}
      {...props}
    >
      <path
        fill={color}
        d="M13 4a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V7a3 3 0 0 0-3-3Zm-4 6.75a.748.748 0 0 0-.75-.75.748.748 0 0 0-.75.75V12a5.502 5.502 0 0 0 4.75 5.45v1.05h-1.5a.748.748 0 0 0-.75.75c0 .416.334.75.75.75h4.5c.416 0 .75-.334.75-.75a.748.748 0 0 0-.75-.75h-1.5v-1.05A5.502 5.502 0 0 0 18.5 12v-1.25a.748.748 0 0 0-.75-.75.748.748 0 0 0-.75.75V12a4 4 0 1 1-8 0v-1.25Z"
      />
    </svg>
  );
};

export default MicrophoneIcon;
