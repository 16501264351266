import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/components/Button/Button';

interface Props {
  onClick: () => void;
}

const PermissionsDisclaimer = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'mt-4 flex flex-col rounded-2xl border-[1px] border-[#D4D4D8] bg-white p-4',
        'sm:mb-0 sm:w-[48%] sm:justify-between',
        'md:mb-4 md:mt-0 md:w-full md:max-w-[400px]'
      )}
    >
      <p className="mb-4 text-base font-semibold text-[#09090B]">
        {t('askPermissions.disclaimers.permissions.title')}
      </p>
      <p className="text-sm font-normal text-[#222222] opacity-65">
        {t('askPermissions.disclaimers.permissions.subtitle')}
      </p>
      <Button
        className="default alt mt-4 !rounded-lg"
        onClick={onClick}
      >
        {t('askPermissions.disclaimers.permissions.button')}
      </Button>
    </div>
  );
};

export default PermissionsDisclaimer;
