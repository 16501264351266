import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  Assessment,
  AssessmentStep,
  CustomQuestionnaireQuestionStep,
  FilterQuestionStep,
  HardSkillQuestionStep,
  SoftSkillQuestionStep,
} from '../../interfaces/assessment';
import { CompanyData } from '../../interfaces/companyData';
import { FilterQuestionsData } from '../../interfaces/filterQuestionsData';
import { JobPostData } from '../../interfaces/jobPostData';
import { RetakeDetails } from '../../interfaces/retakeDetails';
import {
  ExamFoundPayload,
  ExamUpdatedPayload,
  JobPostFoundPayload,
} from '../../shared/socket/socket.interfaces';

const { VITE_APP_INTERVIEW_DURATION, VITE_APP_PRESENTATION_INTERVIEW_DURATION } = import.meta.env;

export interface ExamState {
  assessment: Assessment | null;
  companyData: CompanyData | null;
  jobPostData: JobPostData | null;
  currentStep: AssessmentStep | null;
  currentStepIndex: number;
  loading: boolean;
  completed_chunks: number;
  presentation_interview_completed_chunks: number;
  global_hard_skill_timer: number; // This is a timer used for a single hard skill
  global_custom_questionnaire_timer: number | null;
  filters_result: FilterQuestionsData | null;
  soft_skill_question_retake_details: RetakeDetails | null;
  interview_simulation_done: boolean;
}

const initialState: ExamState = {
  assessment: null,
  companyData: null,
  jobPostData: null,
  currentStep: null,
  currentStepIndex: -1,
  loading: true,
  completed_chunks: 0,
  presentation_interview_completed_chunks: 0,
  global_hard_skill_timer: 0,
  global_custom_questionnaire_timer: null,
  filters_result: null,
  soft_skill_question_retake_details: null,
  interview_simulation_done: false,
};

const slice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    /** Global */
    RETRIEVE_EXAM: (state, action: PayloadAction<ExamFoundPayload>) => {
      const assessment = action.payload.assessment;
      for (const step of assessment.steps) {
        // Update soft skills timers with our environment variable
        if (step.type === 'briefing' && step.group === 'soft-skills') {
          step.data.duration = Number(VITE_APP_INTERVIEW_DURATION) * step.data.questions; // 3 videos -> 180 * 3 = 540 seconds duration
        }
        //TODO: Remove this when type:video steps have their own duration from backend
        // Update presentation interview
        else if (step.type === 'video') {
          step.data.duration = Number(VITE_APP_PRESENTATION_INTERVIEW_DURATION);
        }
      }
      return {
        ...state,
        assessment,
        currentStepIndex: 0,
        companyData: action.payload.companyData ?? state.companyData,
        jobPostData: action.payload.jobPostData ?? state.jobPostData,
        loading: false,
      };
    },
    RETRIEVE_JOB_POST: (state, action: PayloadAction<JobPostFoundPayload>) => ({
      ...state,
      companyData: action.payload.companyData,
      jobPostData: action.payload.jobPostData,
      loading: false,
    }),
    UPDATE_ASSESSMENT: (_state, action: PayloadAction<ExamUpdatedPayload>) => {
      const assessment = action.payload.assessment;
      for (const step of assessment.steps) {
        // Update soft skills timers with our environment variable
        if (step.type === 'briefing' && step.group === 'soft-skills') {
          step.data.duration = Number(VITE_APP_INTERVIEW_DURATION) * step.data.questions; // 3 videos -> 180 * 3 = 540 seconds duration
        }
        //TODO: Remove this when type:video steps have their own duration from backend
        // Update presentation interview
        else if (step.type === 'video') {
          step.data.duration = Number(VITE_APP_PRESENTATION_INTERVIEW_DURATION);
        }
      }
    },
    // This is used to resume the assessment. The user will be redirected to the first not completed step
    GET_NEXT_STEP_TO_COMPLETE: (state) => {
      const index = state.assessment?.steps.findIndex((s) => !s.completed) || 0;
      const next = state.assessment?.steps[index] || null;
      return {
        ...state,
        currentStep: next,
        currentStepIndex: index,
        global_hard_skill_timer:
          next?.type === 'hard-skill-question' && state.currentStep?.type !== 'hard-skill-question' // Update the hard skill timer if this is the first question of a new hard skill questionnaire
            ? next.user_data.elapsed_time
            : state.global_hard_skill_timer,
        global_custom_questionnaire_timer:
          next?.type === 'custom-questionnaire-question' &&
          state.currentStep?.type !== 'custom-questionnaire-question' // Update the custom questionnaire timer if this is the first question of a new custom questionnaire
            ? next.user_data.elapsed_time
            : state.global_custom_questionnaire_timer,
      };
    },
    // This is used to get the next step in the assessment (such as in filter questions or hard skills questions)
    GET_NEXT_STEP: (state) => {
      const index = state.currentStepIndex + 1;
      const next = state.assessment?.steps[index] || null;
      return {
        ...state,
        currentStep: next,
        currentStepIndex: index,
        global_hard_skill_timer:
          next?.type === 'hard-skill-question' && state.currentStep?.type !== 'hard-skill-question' // Update the hard skill timer if this is the first question of a new hard skill questionnaire
            ? next.user_data.elapsed_time
            : state.global_hard_skill_timer,
        global_custom_questionnaire_timer:
          next?.type === 'custom-questionnaire-question' &&
          state.currentStep?.type !== 'custom-questionnaire-question' // Update the custom questionnaire timer if this is the first question of a new custom questionnaire
            ? next.user_data.elapsed_time
            : state.global_custom_questionnaire_timer,
      };
    },
    // This is used to get the previous step in the assessment (such as in filter questions or hard skills questions)
    GET_PREVIOUS_STEP: (state) => {
      const index = state.currentStepIndex - 1;
      const previous = state.assessment?.steps[index] || null;
      return {
        ...state,
        currentStep: previous,
        currentStepIndex: index,
      };
    },
    // This is used to set the current step as completed
    SET_STEP_COMPLETED: (state) => {
      const step = state.assessment?.steps[state.currentStepIndex];
      if (step) {
        step.completed = true;
      }
    },
    ENABLE_LOADING: (state, _: PayloadAction<void>) => ({
      ...state,
      loading: true,
    }),
    DISABLE_LOADING: (state, _: PayloadAction<void>) => ({
      ...state,
      loading: false,
    }),

    /** Global videos */
    VIDEO_INTERVIEW_START: (state) => ({
      ...state,
      completed_chunks: 0,
    }),
    UPDATE_COMPLETED_CHUNKS: (state) => ({
      ...state,
      completed_chunks: state.completed_chunks + 1,
    }),
    /** Filter Questions */
    STORE_FILTER_QUESTION_ANSWER: (state, action: PayloadAction<{ answer_id: number }>) => {
      const step = state.assessment?.steps[state.currentStepIndex] as FilterQuestionStep | null;
      if (step) {
        step.user_data = {
          answer_id: action.payload.answer_id,
        };
      }
    },
    KILLER_QUESTIONS_FAILED: (state, action: PayloadAction<FilterQuestionsData>) => ({
      ...state,
      filters_result: action.payload,
      loading: false,
    }),

    /** Presentation Interview */
    PRESENTATION_INTERVIEW_START: (state) => ({
      ...state,
      presentation_interview_completed_chunks: 0,
    }),
    UPDATE_PRESENTATION_INTERVIEW_COMPLETED_CHUNKS: (state) => ({
      ...state,
      presentation_interview_completed_chunks: state.presentation_interview_completed_chunks + 1,
    }),

    /** Soft Skills */
    INCREASE_RETAKE_COUNT: (state) => {
      const step = state.currentStep as SoftSkillQuestionStep | null;
      if (step && step.data.retake) {
        step.data.retake.current += 1;
      }
    },
    SOFT_SKILL_QUESTION_RETAKE: (state, action: PayloadAction<RetakeDetails>) => ({
      ...state,
      soft_skill_question_retake_details: action.payload,
    }),

    /** Hard Skills */
    STORE_HARD_SKILL_QUESTION_ANSWER: (state, action: PayloadAction<{ answer_id: number }>) => {
      const step = state.assessment?.steps[state.currentStepIndex] as HardSkillQuestionStep | null;
      const currentStep = state.currentStep as HardSkillQuestionStep | null;
      if (step && currentStep) {
        step.user_data = {
          ...step.user_data,
          answer_id: action.payload.answer_id,
        };
        currentStep.user_data = {
          ...currentStep.user_data,
          answer_id: action.payload.answer_id,
        };
      }
    },
    UPDATE_HARD_SKILL_TIMER: (state) => ({
      ...state,
      global_hard_skill_timer: state.global_hard_skill_timer + 1,
    }),

    /** Custom Questionnaire */
    STORE_CUSTOM_QUESTIONNAIRE_QUESTION_ANSWER: (
      state,
      action: PayloadAction<{ answer_id: number }>
    ) => {
      const step = state.assessment?.steps[
        state.currentStepIndex
      ] as CustomQuestionnaireQuestionStep | null;
      const currentStep = state.currentStep as CustomQuestionnaireQuestionStep | null;
      if (step && currentStep) {
        step.user_data = {
          ...step.user_data,
          answer_id: action.payload.answer_id,
        };
        currentStep.user_data = {
          ...currentStep.user_data,
          answer_id: action.payload.answer_id,
        };
      }
    },
    UPDATE_CUSTOM_QUESTIONNAIRE_TIMER: (state) => ({
      ...state,
      global_custom_questionnaire_timer:
        state.global_custom_questionnaire_timer !== null
          ? state.global_custom_questionnaire_timer + 1
          : null,
    }),
    INTERVIEW_SIMULATION_DONE: (state) => ({
      ...state,
      interview_simulation_done: true,
    }),
  },
});

export default slice.reducer;

export const actions = { ...slice.actions };
