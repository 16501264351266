import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
  color?: string;
}

const ThumbsUpCircleGreenIcon = ({ color = '#1C1917', ...props }: Props) => {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.705 28h1.333V10.667H5.705a2.667 2.667 0 00-2.667 2.666v12A2.667 2.667 0 005.705 28zm21.333-17.333h-9.333l1.496-4.491a2.667 2.667 0 00-2.53-3.51h-.3L9.706 9.918V28h14.667l5.25-11.532.083-.39v-2.745a2.667 2.667 0 00-2.667-2.666z"
        fill={color}
      />
    </svg>
  );
};

export default ThumbsUpCircleGreenIcon;
