import { SVGAttributes } from 'react';

export default function ArrowRotateLeftIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.276 1.5c.428 0 .776.347.776.776v3.3c1.283-2.15 3.63-3.555 6.21-3.555A7.244 7.244 0 0116.5 9.259a7.245 7.245 0 01-11.877 5.564.776.776 0 11.994-1.192 5.707 5.707 0 003.642 1.317 5.697 5.697 0 005.69-5.69 5.697 5.697 0 00-5.69-5.69c-2.151 0-4.1 1.238-5.06 3.104h3.25a.776.776 0 010 1.552H2.275a.775.775 0 01-.776-.776V2.276c0-.429.348-.776.776-.776z"
        fill={props.color ?? '#000'}
      />
    </svg>
  );
}
