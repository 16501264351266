import { SVGAttributes } from 'react';

export default function EditPencil({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M5.34517 13.2417L13.7969 4.78997L12.6184 3.61146L4.16667 12.0632V13.2417H5.34517ZM6.03553 14.9084H2.5V11.3729L12.0292 1.84369C12.3547 1.51825 12.8822 1.51825 13.2077 1.84369L15.5647 4.20071C15.8902 4.52615 15.8902 5.05378 15.5647 5.37922L6.03553 14.9084ZM2.5 16.5751H17.5V18.2417H2.5V16.5751Z"
        fill={props.color ?? '#000'}
      />
    </svg>
  );
}
