import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const EyeOpenIcon = ({ color, ...props }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.24721 11.7781C8.24721 9.32315 10.2369 7.33348 12.6918 7.33348C15.1468 7.33348 17.1365 9.32315 17.1365 11.7781C17.1365 14.2331 15.1468 16.2227 12.6918 16.2227C10.2369 16.2227 8.24721 14.2331 8.24721 11.7781ZM12.6918 14.556C14.2266 14.556 15.4697 13.3129 15.4697 11.7781C15.4697 10.2433 14.2266 9.00022 12.6918 9.00022C12.6675 9.00022 12.6467 9.00022 12.5911 9.00022C12.6675 9.17731 12.6918 9.36481 12.6918 9.5558C12.6918 10.7815 11.6953 11.7781 10.4695 11.7781C10.2785 11.7781 10.091 11.7538 9.91395 11.6774C9.91395 11.733 9.91395 11.7538 9.91395 11.7469C9.91395 13.3129 11.1571 14.556 12.6918 14.556ZM6.00475 6.79873C7.63954 5.27922 9.88617 4 12.6918 4C15.4975 4 17.7441 5.27922 19.3796 6.79873C21.0047 8.30574 22.0916 10.0801 22.6055 11.351C22.7201 11.6253 22.7201 11.9309 22.6055 12.2052C22.0916 13.4449 21.0047 15.2192 19.3796 16.7575C17.7441 18.2784 15.4975 19.5562 12.6918 19.5562C9.88617 19.5562 7.63954 18.2784 6.00475 16.7575C4.37968 15.2192 3.29352 13.4449 2.77687 12.2052C2.66292 11.9309 2.66292 11.6253 2.77687 11.351C3.29352 10.0801 4.37968 8.30574 6.00475 6.79873ZM12.6918 5.66674C10.4279 5.66674 8.56667 6.69456 7.13952 8.01753C5.80266 9.26064 4.8797 10.7052 4.40815 11.7781C4.8797 12.8198 5.80266 14.2956 7.13952 15.5387C8.56667 16.8617 10.4279 17.8895 12.6918 17.8895C14.9558 17.8895 16.817 16.8617 18.2442 15.5387C19.581 14.2956 20.4734 12.8198 20.9769 11.7781C20.4734 10.7052 19.581 9.26064 18.2442 8.01753C16.817 6.69456 14.9558 5.66674 12.6918 5.66674Z"
        fill={color}
      />
    </svg>
  );
};

export default EyeOpenIcon;
