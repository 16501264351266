import { SVGAttributes } from 'react';

export default function QuestionsNumberIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={48}
        height={48}
        rx={24}
        fill="#F86F5D"
        fillOpacity={0.2}
      />
      <path
        d="M36 16.083V30.75c0 .486-.194.953-.54 1.296a1.853 1.853 0 01-1.306.537H18.75l-3.701 3.221a1.841 1.841 0 01-1.986.272 1.823 1.823 0 01-.777-.675 1.806 1.806 0 01-.286-.984V16.083c0-.486.194-.952.54-1.296a1.853 1.853 0 011.306-.537h20.308c.49 0 .96.193 1.305.537.346.344.541.81.541 1.296z"
        fill="#F86F5D"
      />
      <g filter="url(#filter0_d_159_6733)">
        <path
          d="M28.804 24.988v-.143c.003-.494.045-.887.125-1.18.083-.293.203-.53.361-.71.158-.181.348-.345.57-.491.166-.113.315-.23.446-.351.131-.121.235-.255.313-.402.078-.15.116-.316.116-.499a.972.972 0 00-.132-.512.899.899 0 00-.357-.338 1.02 1.02 0 00-.49-.118c-.174 0-.339.04-.494.122a.96.96 0 00-.381.355 1.13 1.13 0 00-.16.58h-1.639c.013-.564.142-1.03.386-1.396.243-.369.566-.644.967-.824a3.162 3.162 0 011.329-.275c.533 0 1.004.093 1.413.279.41.183.73.45.964.8.233.349.349.77.349 1.264 0 .33-.052.623-.157.88-.101.253-.244.479-.43.676a3.35 3.35 0 01-.654.528 2.67 2.67 0 00-.521.415 1.42 1.42 0 00-.302.499 2.257 2.257 0 00-.1.698v.143h-1.522zm.795 2.707a.919.919 0 01-.686-.296 1 1 0 01-.277-.72.98.98 0 01.277-.71.919.919 0 01.686-.296.91.91 0 01.67.297c.194.197.291.434.294.71a1.068 1.068 0 01-.49.88.877.877 0 01-.474.135z"
          fill="#fff"
        />
      </g>
      <g filter="url(#filter1_d_159_6733)">
        <path
          fill="#fff"
          d="M16.0916 18.8059H26.00612V20.58368H16.0916z"
        />
      </g>
      <g filter="url(#filter2_d_159_6733)">
        <path
          fill="#fff"
          d="M16.0916 22.3609H26.00612V24.13868H16.0916z"
        />
      </g>
      <g filter="url(#filter3_d_159_6733)">
        <path
          fill="#fff"
          d="M16.0916 25.9163H23.30216V27.69408H16.0916z"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_159_6733"
          x={27.082}
          y={18.8059}
          width={6.29685}
          height={9.77777}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6733"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6733"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_159_6733"
          x={16.0916}
          y={18.8059}
          width={10.8034}
          height={2.66666}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6733"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6733"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_159_6733"
          x={16.0916}
          y={22.3609}
          width={10.8034}
          height={2.66666}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6733"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6733"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_159_6733"
          x={16.0916}
          y={25.9163}
          width={8.09934}
          height={2.66666}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6733"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6733"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
