import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/components/Button/Button';
import CompanyDetailsHeader from '../../shared/components/CompanyDetailsHeader/CompanyDetailsHeader';
import HeadingText from '../../shared/components/HeadingText/HeadingText';
import CheckmarkIcon from '../../shared/components/Icons/CheckmarkIcon';
import { SupportEmail } from '../../shared/components/SupportEmail/SupportEmail';
import { actions } from '../../store/exam/reducer';
import { useAppDispatch } from '../../store/store';

const InterviewSimulationDone = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const submitHandler = () => {
    dispatch(actions.INTERVIEW_SIMULATION_DONE());
    dispatch(actions.GET_NEXT_STEP_TO_COMPLETE());
  };

  return (
    <div className="mx-auto flex h-full flex-col pb-12 md:max-w-[1024px]">
      {/* Assessment details */}
      <CompanyDetailsHeader />
      <div className="m-auto flex h-full w-full max-w-5xl items-center justify-center">
        <div
          className={clsx(
            'flex h-full w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff]',
            'lg:max-h-[576px] lg:shadow-cust-1'
          )}
        >
          <div className="mb-8 flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#1F883D]">
            <CheckmarkIcon
              color="white"
              width={32}
              height={26}
            />
          </div>
          <div className="text-center">
            <HeadingText className="font-bold">{t('interviewSimulation.done.text')}</HeadingText>
          </div>
          <div
            className={clsx(
              'fixed bottom-0 z-10 flex w-full flex-col items-center justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] pt-4',
              'lg:static lg:mt-8 lg:justify-center lg:border-0 lg:pb-0'
            )}
          >
            <Button
              className="default mb-4"
              onClick={submitHandler}
            >
              {t('interviewSimulation.done.button')}
            </Button>
            {/* Footer Mobile */}
            <div className="flex w-full flex-col items-center justify-center bg-[var(--page-background-color)] p-4 md:hidden">
              <div className="text-sm">
                {t('needHelp')} {t('contactUs')} <SupportEmail />
              </div>
              <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
            </div>
          </div>
        </div>
      </div>
      {/* Contact support */}
      {/* <Footer /> */}
      {/* Footer Desktop */}
      <div className="mb-6 hidden flex-col items-center justify-center pt-4 md:flex">
        <div className="text-sm">
          {t('needHelp')} {t('contactUs')} <SupportEmail />
        </div>
        <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
      </div>
    </div>
  );
};

export default InterviewSimulationDone;
