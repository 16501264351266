import { useRef, useState } from 'react';

const useAudioAnalyser = (
  timingMs: number,
  limitMs: number,
  volumeThreshold: number,
  cb: () => void
) => {
  const [avg, setAvg] = useState<number>(0);

  // Refs
  const interval = useRef<ReturnType<typeof setInterval>>();
  const counter = useRef<number>(0);
  const levels = useRef<number>(0);

  // Handlers
  const startAnalyser = async () => {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        sampleSize: 16,
        channelCount: 1,
        noiseSuppression: true, // not supported on Safari desktop, Safari iOS, Firefox iOS and Chrome iOS
        echoCancellation: true,
      },
    });
    const ctx = new AudioContext();
    const source = ctx.createMediaStreamSource(audioStream);
    const analyser = ctx.createAnalyser();
    source.connect(analyser);
    analyser.fftSize = 256;
    interval.current = setInterval(() => {
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(dataArray);
      const max = Math.max(...dataArray);
      const min = Math.min(...dataArray);
      const diff = max - min;
      counter.current++;
      levels.current += diff;
      const diffAvg = levels.current / counter.current;
      setAvg(diffAvg);
      if (counter.current >= limitMs / 1000) {
        if (diffAvg < volumeThreshold) {
          stopAnalyser();
          cb();
        }
      }
    }, timingMs);
  };

  const stopAnalyser = () => {
    clearInterval(interval.current);
    interval.current = undefined;
    counter.current = 0;
    levels.current = 0;
  };

  return {
    avg,
    startAnalyser,
    stopAnalyser,
  };
};

export default useAudioAnalyser;
