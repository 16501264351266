import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
  color?: string;
}

export default function CircleExclamation({ color = '#CF6400', ...props }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M20.005 39.2a19.2 19.2 0 1 0 0-38.4 19.2 19.2 0 0 0 0 38.4Zm0-28.8c.997 0 1.8.802 1.8 1.8v8.4c0 .997-.803 1.8-1.8 1.8-.998 0-1.8-.803-1.8-1.8v-8.4c0-.998.802-1.8 1.8-1.8Zm-2.4 16.8a2.4 2.4 0 1 1 4.799 0 2.4 2.4 0 0 1-4.8 0Z"
      />
    </svg>
  );
}
