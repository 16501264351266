import clsx from 'clsx';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  description: string;
}

const DescriptionText = ({ description, className }: Props) => {
  return (
    <div
      className={clsx('description-text ', className)}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export default DescriptionText;
