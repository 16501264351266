import clsx from 'clsx';
import { MouseEvent, PropsWithChildren, useState } from 'react';
import { getCompanyBrandCustomizations } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import { convertHexToRGBA } from '../../utils/colors';
import AngleDownIcon from '../Icons/AngleDownIcon';
import CircleInfoIcon from '../Icons/CircleInfoIcon';

interface Props extends PropsWithChildren {
  title: string;
}

const Collapsible = ({ title, children }: Props) => {
  // Selectors
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  // States
  const [isOpen, setIsOpen] = useState(false);

  // Computed
  const backgroundColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 5);
  const borderColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 25);

  // Handler
  const toggleOpen = (_: MouseEvent<HTMLDivElement>): void => {
    const windowSize = window.innerWidth;
    if (windowSize < 1024) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div
      className={clsx(
        'h-full overflow-y-hidden rounded-2xl border p-4 transition-all',
        'lg:!max-h-[100vh] lg:p-6',
        {
          'max-h-[62px]': !isOpen,
          'max-h-[100vh]': isOpen,
        }
      )}
      style={{ backgroundColor, borderColor }}
    >
      {/* Title */}
      <div
        className={clsx(
          'flex cursor-pointer flex-row items-center justify-start',
          'lg:cursor-default'
        )}
        onClick={toggleOpen}
      >
        <CircleInfoIcon color="var(--accent-color)" />
        <h3
          className={clsx('ml-2 flex-1 text-lg font-semibold text-[#09090b]')}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <AngleDownIcon
          className={clsx('transition-all', 'lg:hidden', { 'rotate-180': isOpen })}
          color="var(--accent-color)"
        />
      </div>

      {/* Content */}
      <div className={clsx('mt-4')}>{children}</div>
    </div>
  );
};

export default Collapsible;
