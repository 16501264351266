import { SVGAttributes } from 'react';

export default function CheckmarkIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.244 3.986c.341.317.341.828 0 1.115l-8.845 8.891c-.284.344-.793.344-1.108 0l-4.556-4.58c-.313-.286-.313-.798 0-1.115a.805.805 0 011.138 0l3.987 4.011 8.275-8.322c.315-.315.824-.315 1.109 0z"
        fill={props.color ?? '#000'}
      />
    </svg>
  );
}
