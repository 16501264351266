import { SVGAttributes } from 'react';

export default function QuestionnaireIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={48}
        height={48}
        rx={24}
        fill="#6B52FF"
        fillOpacity={0.2}
      />
      <path
        d="M36 16.833V31.5c0 .486-.194.953-.54 1.296a1.853 1.853 0 01-1.306.537H18.75l-3.701 3.221a1.841 1.841 0 01-1.986.272 1.823 1.823 0 01-.777-.675 1.806 1.806 0 01-.286-.984V16.833c0-.486.194-.952.54-1.296A1.853 1.853 0 0113.847 15h20.308c.49 0 .96.193 1.305.537.346.344.541.81.541 1.296z"
        fill="#6B52FF"
      />
      <g filter="url(#filter0_d_159_6732)">
        <path
          fill="#fff"
          d="M21 19.7499H32.25V21.2499H21z"
        />
      </g>
      <g filter="url(#filter1_d_159_6732)">
        <path
          fill="#fff"
          d="M21 23.4999H32.25V24.9999H21z"
        />
      </g>
      <g filter="url(#filter2_d_159_6732)">
        <path
          fill="#fff"
          d="M21 27.2499H32.25V28.7499H21z"
        />
      </g>
      <g filter="url(#filter3_d_159_6732)">
        <circle
          cx={18}
          cy={20.4999}
          r={1.27778}
          stroke="#fff"
          strokeWidth={0.444444}
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter4_d_159_6732)">
        <circle
          cx={18}
          cy={24.2499}
          r={1.5}
          fill="#fff"
        />
      </g>
      <g filter="url(#filter5_d_159_6732)">
        <circle
          cx={18}
          cy={27.9999}
          r={1.27778}
          stroke="#fff"
          strokeWidth={0.444444}
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_159_6732"
          x={21}
          y={19.7499}
          width={12.1389}
          height={2.38889}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_159_6732"
          x={21}
          y={23.4999}
          width={12.1389}
          height={2.38889}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_159_6732"
          x={21}
          y={27.2499}
          width={12.1389}
          height={2.38889}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_159_6732"
          x={16.5}
          y={18.9999}
          width={3.88889}
          height={3.88889}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_159_6732"
          x={16.5}
          y={22.7499}
          width={3.88889}
          height={3.88889}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_159_6732"
          x={16.5}
          y={26.4999}
          width={3.88889}
          height={3.88889}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
