import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const VideoIcon = ({ color = '#525252', ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={13}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M0 2.5c0-1.103.897-2 2-2h8c1.103 0 2 .897 2 2v8c0 1.103-.897 2-2 2H2c-1.103 0-2-.897-2-2v-8Zm17.472-.881A1 1 0 0 1 18 2.5v8a1 1 0 0 1-1.556.831l-3-2L13 9.034V3.966l.444-.297 3-2c.306-.203.7-.225 1.028-.05Z"
      />
    </svg>
  );
};

export default VideoIcon;
