import { SVGAttributes } from 'react';

export default function WiFiGood({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.69 6.997A17.925 17.925 0 0112 3c4.285 0 8.22 1.497 11.31 3.997l-1.256 1.556A15.933 15.933 0 0012 5C8.19 5 4.694 6.33 1.946 8.553L.69 6.997zm3.141 3.89A12.946 12.946 0 0112 8c3.094 0 5.936 1.081 8.169 2.886l-1.257 1.556A10.954 10.954 0 0012 10c-2.618 0-5.023.915-6.912 2.442l-1.257-1.556zm3.142 3.89A7.966 7.966 0 0112 13c1.904 0 3.653.665 5.027 1.776l-1.257 1.556A5.975 5.975 0 0012 15c-1.428 0-2.74.499-3.77 1.332l-1.257-1.556zm3.142 3.89A2.988 2.988 0 0112 18c.714 0 1.37.25 1.885.666L12 21l-1.885-2.334z"
        fill={props.color ?? '#000'}
      />
    </svg>
  );
}
