import { useEffect } from 'react';
import { useSocketInstance } from '../../../App';
import { actions as examActions } from '../../../store/exam/reducer';
import { useAppDispatch } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import IntroBox from './IntroBox';

const Intro = () => {
  const { socket } = useSocketInstance();

  const dispatch = useAppDispatch();

  const submitHandler = (): void => {
    dispatch(uiActions.INTRO_SUBMIT());
    dispatch(examActions.SET_STEP_COMPLETED());
    socket.emit('policies-accepted');
  };

  useEffect(() => {
    dispatch(uiActions.INTRO_START());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <IntroBox onSubmit={submitHandler} />;
};

export default Intro;
