import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import { getCompanyData, getJobPostData } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import HeadingText from '../HeadingText/HeadingText';

interface Props extends HTMLAttributes<HTMLDivElement> {
  logoOnly?: boolean;
}

const JobHeader = ({ logoOnly = false, ...props }: Props) => {
  const company = useAppSelector(getCompanyData);
  const job_post = useAppSelector(getJobPostData);

  return (
    company &&
    job_post && (
      <div
        className={clsx(
          'flex w-full flex-row items-stretch justify-start gap-4 px-4 lg:px-0',
          props.className
        )}
      >
        <div
          style={{ backgroundImage: `url('${company.logo}')` }}
          className="br-no-repeat h-12 w-12 rounded-[8px] bg-cover bg-center"
        />
        {!logoOnly && (
          <div className="flex flex-1 flex-col items-start justify-center">
            <HeadingText className="font-bold">{job_post.name}</HeadingText>
            <HeadingText variant="secondary">{company.name}</HeadingText>
          </div>
        )}
      </div>
    )
  );
};

export default JobHeader;
