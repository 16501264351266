import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const EyeCloseIcon = ({ color, ...props }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.40074 6.89903C8.7851 5.82092 10.5601 4.99999 12.6913 4.99999C15.2163 4.99999 17.2382 6.15123 18.71 7.51871C20.1725 8.87495 21.1506 10.4718 21.6131 11.6156C21.7162 11.8624 21.7162 12.1374 21.6131 12.3843C21.1944 13.3937 20.3538 14.8155 19.1163 16.0843L22.4037 18.6592C22.7287 18.9155 22.7881 19.3874 22.5319 19.7124C22.2756 20.0374 21.8037 20.0967 21.4787 19.8405L2.97877 5.3403C2.65278 5.08467 2.59562 4.61343 2.85115 4.28737C3.10671 3.96137 3.57796 3.90421 3.9042 4.15974L7.40074 6.89903ZM8.6226 7.85934L10.0601 8.98433C10.7632 8.37183 11.6851 7.99996 12.6913 7.99996C14.9007 7.99996 16.6913 9.79057 16.6913 11.9999C16.6913 12.6624 16.5319 13.2843 16.2475 13.8343L17.9288 15.153C18.9756 14.0874 19.7506 12.8468 20.1475 11.9999C19.6944 11.0343 18.8913 9.73432 17.6881 8.61558C16.4038 7.42497 14.7288 6.47185 12.6913 6.47185C11.1132 6.47185 9.72572 7.05403 8.6226 7.85934ZM15.0319 12.8812C15.135 12.6062 15.1913 12.3093 15.1913 11.9718C15.1913 10.6187 14.0726 9.47182 12.6913 9.47182C12.6694 9.47182 12.6507 9.49995 12.6007 9.49995C12.6694 9.65932 12.6913 9.82807 12.6913 9.97182C12.6913 10.3187 12.6163 10.6187 12.4851 10.8843L15.0319 12.8812ZM15.3257 16.953L16.635 17.9843C15.5007 18.5967 14.1851 18.9999 12.6913 18.9999C10.1663 18.9999 8.14448 17.8499 6.67262 16.4811C5.21075 15.0968 4.23326 13.4999 3.76827 12.3843C3.66577 12.1374 3.66577 11.8624 3.76827 11.6156C4.06639 10.8999 4.57545 9.97494 5.28794 9.04683L6.46637 9.97494C5.882 10.7031 5.49638 11.4249 5.23669 11.9718C5.66075 12.9374 6.49137 14.2655 7.69448 15.3843C8.97885 16.5749 10.6538 17.4999 12.6913 17.4999C13.6507 17.4999 14.5288 17.2936 15.3257 16.953ZM8.69135 11.9718C8.69135 11.9093 8.69447 11.8218 8.70072 11.7343L10.4538 13.1155C10.782 13.7749 11.3945 14.2687 12.1288 14.4093L13.8851 15.8186C13.5069 15.9093 13.1069 15.9999 12.6632 15.9999C10.482 15.9999 8.66323 14.2093 8.66323 11.9718H8.69135Z"
        fill={color}
      />
    </svg>
  );
};

export default EyeCloseIcon;
