import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../App';
import Button from '../../shared/components/Button/Button';
import CompanyDetailsHeader from '../../shared/components/CompanyDetailsHeader/CompanyDetailsHeader';
import ConnectionStatus from '../../shared/components/ConnectionStatus/ConnectionStatus';
import AudioAnalyserDialog from '../../shared/components/Dialog/contents/AudioAnalyserDialog';
import Dialog from '../../shared/components/Dialog/Dialog';
import ConnectionIcon from '../../shared/components/Icons/ConnectionIcon';
import EyeCloseIcon from '../../shared/components/Icons/EyeCloseIcon';
import EyeOpenIcon from '../../shared/components/Icons/EyeOpenIcon';
import MicrophoneIcon from '../../shared/components/Icons/MicrophoneIcon';
import RecLittleIcon from '../../shared/components/Icons/RecLittleIcon';
import VideoIcon from '../../shared/components/Icons/VideoIcon';
import { SupportEmail } from '../../shared/components/SupportEmail/SupportEmail';
import useAudioAnalyser from '../../shared/hooks/useAudioAnalyser';
import { convertHexToRGBA } from '../../shared/utils/colors';
import { getCountDown } from '../../shared/utils/getCountDown';
import { getCompanyBrandCustomizations } from '../../store/exam/selectors';
import { useAppSelector } from '../../store/store';
import InterviewSimulationDone from './InterviewSimulationDone';
import TestBanner from './TestBanner';

const DURATION: number = +import.meta.env.VITE_APP_INTERVIEW_DURATION;
const MIN_INTERVIEW_DURATION: number = +import.meta.env.VITE_APP_MIN_INTERVIEW_DURATION;
const TICK_INTERVAL = Number(import.meta.env.VITE_NO_AUDIO_CHECK_TICK_INTERVAL);
const LIMIT = Number(import.meta.env.VITE_NO_AUDIO_CHECK_LIMIT);
const THRESHOLD = Number(import.meta.env.VITE_NO_AUDIO_CHECK_THRESHOLD);

const InterviewSimulation = () => {
  // Hooks
  const { t } = useTranslation();
  const { connected } = useSocketInstance();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const navigate = useNavigate();
  const { startAnalyser, stopAnalyser } = useAudioAnalyser(
    TICK_INTERVAL, // tick interval (in milliseconds)
    LIMIT, // no-audio limit (in milliseconds)
    THRESHOLD, // volume threshold (volume can be between 0.xx and more)
    () => {
      handleStop(); // Stop recording
      setIsDialogOpen(true); // Open dialog
    }
  );

  // Refs
  const player = useRef<HTMLVideoElement | null>(null);
  const stream = useRef<MediaStream | undefined>();
  const recorder = useRef<MediaRecorder | undefined>();
  const elapsed = useRef<number>(0);

  // State
  const [height] = useState<number>(window.innerHeight);
  const [width] = useState<number>(window.innerWidth);
  const [overlay, setOverlay] = useState<boolean>(false);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(5);
  const [recording, setRecording] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>(getCountDown(DURATION, elapsed?.current)); //This gets formatted to 03:00 string.
  const [completed, setCompleted] = useState<boolean>(false);
  const [enableStop, setEnableStop] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Selectors
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  // Computed
  const backgroundColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 5);
  const borderColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 25);

  // Handlers
  const handleStop = (): void => {
    setRecording(false);
    stream.current?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    recorder.current && recorder.current.removeEventListener('stop', handleStop);
  };

  const stopButtonClickHandler = useCallback((): void => {
    setSubmitted(true);
    recorder.current && recorder.current.stop();
    stopAnalyser();
    setCompleted(true);
  }, [stopAnalyser]);

  const recordingHandler = async (): Promise<void> => {
    if (!recording) {
      setShowCountdown(true);
    } else {
      stopButtonClickHandler();
    }
  };

  // Effects
  // Handle video and audio stream from user devices
  useEffect(() => {
    const createStream = async (): Promise<void> => {
      if (player && player.current) {
        stream.current = await navigator.mediaDevices.getUserMedia({
          video: {
            width: width > 640 ? 640 : width,
            height: width > 640 ? 360 : height,
            aspectRatio: 16 / 9,
            frameRate: { ideal: 30 },
          },
          audio: {
            sampleSize: 16,
            channelCount: 1,
          },
        });
        try {
          player.current.srcObject = stream.current;
          player.current.onloadedmetadata = () => player.current?.play();
        } catch (e) {
          // Silently suppress eventual errors on page refresh
        }
      }
    };
    createStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  // Handle recording elapsed time
  useEffect(() => {
    let recordingInterval: ReturnType<typeof setInterval> | undefined;

    if (recording) {
      recordingInterval = setInterval(() => {
        elapsed.current = elapsed.current + 1;
        setTimer(getCountDown(DURATION, elapsed.current));
      }, 1000);
    } else {
      clearInterval(recordingInterval);
    }

    return () => {
      clearInterval(recordingInterval);
    };
  }, [recording]);

  // Handle time expiration
  useEffect(() => {
    if (elapsed.current === DURATION) {
      stopButtonClickHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed.current, recorder]);

  // Handle stop recording button
  useEffect(() => {
    if (elapsed.current >= MIN_INTERVIEW_DURATION && !enableStop) {
      setEnableStop(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed.current]);

  // Handle countdown when recording button is pressed
  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;

    if (showCountdown) {
      interval = setInterval(() => {
        setCountdown((state: number) => state - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showCountdown]);

  // Handle countdown expiration
  useEffect(() => {
    if (countdown === 0 && !completed) {
      setShowCountdown(false);
      startAnalyser();
      setRecording(true);

      if (stream && stream.current) {
        recorder.current = new MediaRecorder(stream.current);
        recorder.current.addEventListener('stop', handleStop);
        recorder.current.state === 'inactive' && recorder.current.start(1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, completed, stream, recorder]);

  if (isDialogOpen) {
    return (
      <Dialog isOpen={isDialogOpen}>
        <AudioAnalyserDialog
          onClick={() =>
            navigate(`/${job_post_alias}/${application_alias}/test-devices`, {
              replace: true,
              state: {
                from: 'simulate-interview-prompt',
              },
            })
          }
        />
      </Dialog>
    );
  }

  return submitted ? (
    <InterviewSimulationDone />
  ) : (
    <div className="mx-auto flex h-full flex-col md:max-w-[1024px]">
      {/* Assessment details (hidden on mobile) */}
      <div className="hidden md:block">
        <CompanyDetailsHeader />
      </div>
      {/* Mobile test banner */}
      <TestBanner version="mobile" />

      <div className="m-[auto] flex w-full max-w-5xl flex-1 flex-col items-center justify-center">
        {/* Connection status Banner */}
        <ConnectionStatus
          type="banner"
          connected={connected}
        />

        {/* Card */}
        <div
          className={clsx(
            'm-[0 auto] relative flex h-full w-full flex-col items-center',
            'md:mb-14 md:h-auto',
            'lg:max-h-[576px] lg:max-w-[1024px]'
          )}
        >
          {/* Desktop test banner */}
          <TestBanner version="desktop" />

          <div
            className={clsx(
              'm-[0 auto] relative z-20 flex h-full w-full flex-col items-center gap-6 bg-white p-6',
              'lg:rounded-2xl lg:shadow-cust-1'
            )}
          >
            <div className="flex w-full items-center justify-between">
              {/* Question counter */}
              <div className="text-lg font-bold">
                {t('videoInterview.counter.question')} 1 {t('videoInterview.counter.of')} 1
              </div>
              <div className="flex items-center gap-2">
                {/* Audio, Video, Connection icons */}
                <VideoIcon color="#1F883D" />
                <MicrophoneIcon color="#1F883D" />
                <ConnectionIcon color="#1F883D" />
              </div>
            </div>
            <div
              className={clsx(
                'relative mb-12 flex w-full rounded-lg border bg-[#FFF0E6] p-6 pb-24'
              )}
              style={{ backgroundColor, borderColor }}
            >
              {/* Question text */}
              <div
                className={clsx(
                  'max-h-52 overflow-y-auto text-base font-semibold text-[#111]',
                  'lg:max-h-36 lg:text-2xl'
                )}
              >
                {t('interviewSimulation.question.text')}
              </div>
              {/* Video Mirror */}
              {/* Video overlay */}
              {overlay && (
                <div
                  className={clsx(
                    'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
                    'h-32 w-32 rounded-full border-8 border-white bg-black bg-opacity-60 backdrop-blur-md',
                    { 'z-10': overlay },
                    { '-z-10': !overlay }
                  )}
                ></div>
              )}
              <video
                ref={player}
                className={clsx(
                  'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
                  'h-32 w-32 rounded-full border-8 border-white object-cover'
                )}
                playsInline
                muted={true}
                controls={false}
              />
              {/* Overlay Button*/}
              <div
                className={clsx(
                  'absolute -bottom-14 left-1/2 z-20 translate-x-8',
                  'flex h-10 w-10 cursor-pointer items-center justify-center',
                  'rounded-full border-2 border-white bg-[#f0f0f0] text-white backdrop-blur-lg'
                )}
                onClick={() => setOverlay((state: boolean) => !state)}
              >
                {overlay ? <EyeOpenIcon color="#000" /> : <EyeCloseIcon color="#000" />}
              </div>
            </div>
            {/* Footer */}
            <div
              className={clsx(
                'absolute bottom-0 flex w-full flex-col items-center justify-center',
                'md:static md:bottom-auto'
              )}
            >
              {/* Recording messages */}
              {recording && (
                <span className="flex items-center gap-2 font-bold text-[#CF222E]">
                  <RecLittleIcon color="#CF222E" />
                  {enableStop ? t('videoInterview.recording') : t('videoInterview.min.recording')}
                </span>
              )}
              {!recording && (
                <div
                  className={clsx(
                    'flex w-full flex-1 items-center justify-center border-b border-[#DDDDDD] pb-4 font-bold text-[#111]',
                    'lg:border-none lg:pb-0'
                  )}
                >
                  {t('interviewSimulation.maxTime.message')}
                </div>
              )}
              {/* Rec Button */}
              <Button
                className={clsx(
                  'default my-6 flex gap-2'
                  // TODO: I think this two classes are not necessary, but I will leave them here as a reminder for now
                  // { success: !completed && recording && enableStop }
                  // { transparent: !completed && recording && !enableStop }
                )}
                disabled={completed || (!completed && recording && !enableStop)}
                onClick={
                  (!completed && !recording) || (!completed && recording && enableStop)
                    ? recordingHandler
                    : () => null
                }
              >
                {/* Rec Button text */}
                {!completed && !recording && t('interviewSimulation.button.start')}
                {!completed && recording && !enableStop && t('videoInterview.button.min.record')}
                {!completed && recording && enableStop && t('interviewSimulation.recording.button')}
                {/* Rec timer */}
                {recording && <div className="translate-y-[1px] font-mono">{timer}</div>}
              </Button>

              {/* Footer Mobile */}
              <div className="flex w-full flex-col items-center justify-center bg-[var(--page-background-color)] p-4 md:hidden">
                <div className="text-sm">
                  {t('needHelp')} {t('contactUs')} <SupportEmail />
                </div>
                <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
              </div>
            </div>
            {/* Countdown overlay */}
            <div
              className={clsx(
                'absolute left-0 top-0 z-30 flex h-full w-full flex-col items-center justify-center bg-white bg-opacity-80 p-4 backdrop-blur-md',
                'lg:justify-center',
                { hidden: !showCountdown }
              )}
            >
              {/* Countdown */}
              <div
                className={clsx(
                  'flex h-12 w-12 items-center justify-center rounded-full text-3xl font-extrabold backdrop-blur-lg',
                  {
                    'bg-[#ECECFE] text-[#111]': !brandCustomization?.['accent-color'],
                    'bg-[var(--accent-color)] text-[var(--text-color)]':
                      brandCustomization?.['accent-color'],
                  }
                )}
              >
                {countdown}
              </div>
              {/* Countdown Text */}
              <div className="my-2 text-center text-2xl font-bold text-[#111]">
                {t('videoInterview.title')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Desktop */}
      <div className="mb-6 hidden flex-col items-center justify-center pt-4 md:flex">
        <div className="text-sm">
          {t('needHelp')} {t('contactUs')} <SupportEmail />
        </div>
        <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
      </div>
    </div>
  );
};

export default InterviewSimulation;
