import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import TriangleRedExclamationAltIcon from '../../Icons/TriangleRedExclamationAltIcon';

const { VITE_APP_SUPPORT_EMAIL } = import.meta.env;

interface Props {
  onClick: () => void;
}

const AudioAnalyserDialog = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full max-w-[400px] flex-col bg-white">
      {/* Header */}
      <div className="mb-2 flex flex-col items-center">
        <TriangleRedExclamationAltIcon />
        <h3 className="mt-4 text-center text-lg font-semibold text-black">
          {t('askPermissions.dialog.title')}
        </h3>
      </div>

      {/* Content */}
      <div className="mb-6 flex flex-col items-center">
        <p className="mb-6 text-center text-[15px] font-normal leading-5 text-[#525252]">
          {t('askPermissions.dialog.subtitle')}
        </p>
        <Button
          className="default alt !w-full !rounded-lg"
          onClick={onClick}
        >
          {t('askPermissions.dialog.button')}
        </Button>
      </div>

      {/* Footer */}
      <div className="flex flex-col items-center">
        <p className="mb-2 text-center text-[15px] font-normal leading-5 text-[#525252]">
          {t('askPermissions.dialog.footer.cta')}
        </p>
        <p className="text-center text-[15px] font-normal leading-5 text-[#525252]">
          {t('askPermissions.dialog.footer.email')}
          <a
            className="font-semibold text-[#0078D6] underline"
            href={`mailto:${VITE_APP_SUPPORT_EMAIL}`}
            target="_blank"
            rel="noreferrer"
          >
            {VITE_APP_SUPPORT_EMAIL}
          </a>
        </p>
      </div>
    </div>
  );
};

export default AudioAnalyserDialog;
