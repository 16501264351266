import { SVGAttributes } from 'react';

export default function PlayLittleIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={6}
      height={8}
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 4L0 7.464V.536L6 4z"
        fill={props.color ?? '#000'}
      />
    </svg>
  );
}
