import clsx from 'clsx';

interface Props {
  selected: boolean;
  description: string;
  classes?: string;
  onChange: () => void;
}

const AnswerText = ({ selected, description, classes, onChange }: Props) => {
  return (
    <div
      className={clsx(
        'mb-4 flex w-full cursor-pointer items-center rounded-lg border-2 border-solid bg-[#f7f7f7] p-4 ',
        {
          'border-[var(--accent-color)]': selected,
          'border-transparent': !selected,
        }
      )}
      onClick={onChange}
    >
      <div className="relative w-[32px]">
        <div
          className={clsx(
            'absolute left-[0%] top-[50%] h-[16px] w-[16px] -translate-y-[50%] rounded-full border-2 border-solid',
            {
              'border-[var(--accent-color)]': selected,
              'border-[#DDD]': !selected,
            }
          )}
        >
          <div
            className={clsx(
              'absolute left-[50%] top-[50%] h-[8px] w-[8px] -translate-x-[50%] -translate-y-[50%] rounded-full',
              {
                'border-[var(--accent-color)] bg-[var(--accent-color)]': selected,
                'border-[#DDD] bg-[#ffffff]': !selected,
              }
            )}
          ></div>
        </div>
      </div>
      <div
        className={classes}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default AnswerText;
