import { SVGAttributes } from 'react';

export default function CircleInfoIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill={props.color ?? '#000'}
          d="M8.5 16a8 8 0 1 0 0-16.001A8 8 0 0 0 8.5 16Zm-1.25-5.5H8v-2h-.75a.748.748 0 0 1-.75-.75c0-.416.334-.75.75-.75h1.5c.416 0 .75.334.75.75v2.75h.25c.416 0 .75.334.75.75s-.334.75-.75.75h-2.5a.748.748 0 0 1-.75-.75c0-.416.334-.75.75-.75ZM8.5 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            d="M.5 0h16v16H.5z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
