import { SVGAttributes } from 'react';

export default function VideoRetakeIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={48}
        height={48}
        rx={24}
        fill="#D9F7BA"
      />
      <path
        d="M24 9a15 15 0 100 30.001A15 15 0 0024 9z"
        fill="#8BD62B"
      />
      <g filter="url(#filter0_d_537_2661)">
        <path
          d="M18.704 18.703a7.466 7.466 0 015.3-2.203A7.492 7.492 0 0131.5 24c0 4.144-3.349 7.5-7.495 7.5-3.5 0-6.417-2.39-7.252-5.625h1.951a5.616 5.616 0 005.3 3.75A5.631 5.631 0 0029.635 24a5.631 5.631 0 00-5.63-5.625c-1.556 0-2.945.647-3.958 1.669l3.02 3.018H16.5V16.5l2.204 2.203z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_537_2661"
          x={16.5}
          y={16.5}
          width={15.8889}
          height={15.8889}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_537_2661"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_537_2661"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
