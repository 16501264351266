import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
  color?: string;
}

const ConnectionIcon = ({ color = '#525252', ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={15}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M1.694 5.84A11.957 11.957 0 0 1 10 2.5c3.225 0 6.15 1.272 8.306 3.34a1 1 0 0 0 1.413-.027A1 1 0 0 0 19.69 4.4 13.937 13.937 0 0 0 10 .5 13.961 13.961 0 0 0 .306 4.397a1.005 1.005 0 0 0-.028 1.415.995.995 0 0 0 1.412.029h.004ZM10 7.5c1.775 0 3.394.66 4.631 1.75a1.002 1.002 0 0 0 1.413-.088 1.003 1.003 0 0 0-.088-1.412A8.98 8.98 0 0 0 10 5.5c-2.281 0-4.369.85-5.953 2.25a1 1 0 1 0 1.325 1.5 6.964 6.964 0 0 1 4.63-1.75H10Zm2 5a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"
      />
    </svg>
  );
};

export default ConnectionIcon;
