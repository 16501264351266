import { ReactNode, useEffect, useRef } from 'react';

interface Props {
  isOpen: boolean;
  children: ReactNode;
}

const Dialog = ({ isOpen, children }: Props) => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    if (isOpen) {
      dialog.showModal();
    } else {
      dialog.close();
    }
  }, [isOpen]);

  return (
    <dialog
      ref={dialogRef}
      className="max-w-[90%] rounded-lg bg-white p-6 shadow backdrop:bg-black/65"
    >
      {/*
        NOTE: This component is mostly a wrapper;
        is 'children' content that has to deal with actions (ex: Confirm/Close)
      */}
      {children}
    </dialog>
  );
};

export default Dialog;
