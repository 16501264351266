import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const Title = () => {
  // Hooks
  const { t } = useTranslation();

  return (
    <div className={clsx('mb-4')}>
      <h1 className={clsx('text-xl font-semibold leading-6', 'md:text-2xl md:font-bold')}>
        {t('askPermissions.title')}
      </h1>
    </div>
  );
};

export default Title;
