import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/components/Button/Button';
import Card from '../../../shared/components/Card/Card';
import Collapsible from '../../../shared/components/Collapsible/Collapsible';
import AngleRightIcon from '../../../shared/components/Icons/AngleRightIcon';
import CheckmarkIcon from '../../../shared/components/Icons/CheckmarkIcon';
import ClockIcon from '../../../shared/components/Icons/ClockIcon';
import EditPencil from '../../../shared/components/Icons/EditPencil';
import VideoIcon from '../../../shared/components/Icons/VideoIcon';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import { SupportEmail } from '../../../shared/components/SupportEmail/SupportEmail';
import { actions as examActions } from '../../../store/exam/reducer';
import {
  getCompanyPagesCustomizations,
  getCurrentStep,
  getSummarySteps,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { actions as uiActions } from '../../../store/ui/reducer';
import DescriptionText from '../../Intro/components/DescriptionText';

const Summary = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Selectors
  const currentStep = useAppSelector(getCurrentStep);
  const summarySteps = useAppSelector(getSummarySteps);
  const pagesCustomization = useAppSelector(getCompanyPagesCustomizations);

  // Computed
  const nextStepIndex = summarySteps.findIndex((step) => !step.completed);

  // Handlers
  const submitHandler = (): void => {
    dispatch(uiActions.SUMMARY_SUBMIT());
    dispatch(examActions.SET_STEP_COMPLETED());
    dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
  };

  // Effects
  useEffect(() => {
    dispatch(uiActions.SUMMARY_START());
  }, [dispatch]);

  if (!currentStep || currentStep.type !== 'summary') {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex h-full w-full flex-col',
        'lg:flex-row lg:items-start lg:justify-start lg:p-12'
      )}
    >
      {/* Left column */}
      <div className={clsx('lg:h-full lg:w-full lg:max-w-[40%] lg:py-0')}>
        {/* Column title */}
        <div className={clsx('border border-b-[#e4e4e4] py-4', 'lg:border-none')}>
          <JobHeader />
        </div>

        {/* Collapsible */}
        <div className={clsx('p-4', 'lg:p-0')}>
          <Collapsible title={pagesCustomization?.['assessment-summary-title'] ?? ''}>
            <DescriptionText
              description={pagesCustomization?.['assessment-summary-content'] ?? ''}
            />
          </Collapsible>
        </div>

        {/* Desktop Contact Us */}
        <div className={clsx('hidden flex-col py-4 text-sm', 'lg:mt-8 lg:flex')}>
          <div>
            {t('needHelp')} {t('contactUs')} <SupportEmail />
          </div>
          <span className="mt-1 text-xs font-semibold text-[#525252]">{t('poweredBy')}</span>
        </div>
      </div>

      {/* Right column */}
      <div
        className={clsx(
          'mt-2 flex-1 px-4',
          'lg:ml-12 lg:flex lg:h-full lg:flex-col lg:overflow-y-hidden lg:px-0'
        )}
      >
        {/* Column title */}
        <h3 className={clsx('font-semibold', 'lg:mb-8 lg:px-8 lg:pt-2 lg:text-xl')}>
          {t('summary.list.title')}
        </h3>

        {/* Assessment step list */}
        <div className={clsx('mt-4', 'lg:mt-0 lg:flex-1 lg:overflow-y-auto lg:p-8 lg:pt-[3px]')}>
          {summarySteps.map((step, index) => (
            <Card
              key={`assessment-step-${index}`}
              className={clsx(
                'last-of-type:mb-0',
                'mb-4 md:!p-0 lg:!p-6',
                'md:!p-0',
                'lg:!h-auto lg:!p-6'
              )}
            >
              <div
                className={clsx(
                  'flex w-full flex-col p-4',
                  'lg:space-between lg:flex-row lg:items-center lg:p-0'
                )}
              >
                {/* Title + Content */}
                <div className={clsx('flex flex-col', 'lg:flex-1')}>
                  {/* Title */}
                  <h4 className="font-semibold">{step.title}</h4>

                  {/* Content */}
                  <div className="mt-2 flex items-center justify-start text-sm font-normal text-[#666666]">
                    <div className="mr-4 flex items-center justify-start">
                      {step.type === 'Custom Questions' && (
                        <VideoIcon
                          className="mr-1"
                          color="#666666"
                        />
                      )}
                      {step.type === 'Soft Skill' && (
                        <VideoIcon
                          className="mr-1"
                          color="#666666"
                        />
                      )}
                      {step.type === 'Hard Skill' && (
                        <EditPencil
                          className="mr-1"
                          color="#666666"
                        />
                      )}
                      {step.type === 'Custom Questionnaire' && (
                        <EditPencil
                          className="mr-1"
                          color="#666666"
                        />
                      )}
                      {step.type === 'Custom Questions' && t('summary.customQuestions.subtitle')}
                      {step.type === 'Soft Skill' && t('summary.softSkill.subtitle')}
                      {step.type === 'Hard Skill' && t('summary.hardSkill.subtitle')}
                      {step.type === 'Custom Questionnaire' &&
                        t('summary.customQuestionnaire.subtitle')}
                    </div>
                    {step.duration && (
                      <div className="flex items-center justify-start">
                        <ClockIcon
                          className="mr-1"
                          color="#666666"
                        />
                        {step.duration && step.duration >= 60 && (
                          <>{Math.round(step.duration / 60)} min</>
                        )}
                        {step.duration && step.duration < 60 && <>{step.duration} sec</>}
                      </div>
                    )}
                  </div>
                </div>

                {/* Button */}
                <div className={clsx('mt-4', 'lg:m-0')}>
                  {!step.completed && (
                    <Button
                      className="default !w-28"
                      disabled={nextStepIndex !== index}
                      onClick={submitHandler}
                    >
                      {t('summary.button.next')}
                      <AngleRightIcon
                        color="var(--text-color)"
                        className="ml-1"
                      />
                    </Button>
                  )}
                  {step.completed && (
                    <div
                      className={clsx(
                        'mt-4 flex items-center justify-start font-bold text-[var(--accent-color)]',
                        'lg:m-0'
                      )}
                    >
                      <CheckmarkIcon
                        className="mr-1"
                        color="var(--accent-color)"
                      />
                      {t('summary.step.completed')}
                    </div>
                  )}
                </div>
              </div>
            </Card>
          ))}
        </div>

        {/* Mobile Contact Us */}
        <div className={clsx('flex-col py-4 text-sm', 'lg:hidden')}>
          <div className="flex flex-col items-center justify-center text-center">
            <span className="mb-2 text-base font-semibold text-black">{t('needHelp')}</span>
            <span>
              {t('contactUs')} <SupportEmail />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
