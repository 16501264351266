import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  description: string;
}

const QuestionText = ({ description, ...props }: Props) => {
  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export default QuestionText;
