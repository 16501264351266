import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
  color?: string;
}

const TriangleRedExclamationIcon = ({ color = '#FEF6F6', ...props }: Props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.2769 18.4688L13.9512 3.84375C13.6311 3.28125 13.069 3 12.507 3C11.9449 3 11.3828 3.28125 11.0276 3.84375L2.7058 18.4688C2.10119 19.5897 2.89906 21 4.18281 21H20.8342C22.1129 21 22.9131 19.5938 22.2769 18.4688ZM4.56689 19.0714L12.4718 5.12143L20.4462 19.0714H4.56689ZM12.507 15.2585C11.8293 15.2585 11.2798 15.8242 11.2798 16.5217C11.2798 17.2192 11.8305 17.7849 12.5085 17.7849C13.1865 17.7849 13.7341 17.2192 13.7341 16.5217C13.7326 15.825 13.1861 15.2585 12.507 15.2585ZM11.5702 9.10714V12.9643C11.5702 13.4987 11.9917 13.9286 12.507 13.9286C13.0222 13.9286 13.4437 13.4967 13.4437 12.9643V9.10714C13.4437 8.57679 13.0261 8.14286 12.507 8.14286C11.9878 8.14286 11.5702 8.57679 11.5702 9.10714Z"
        fill={color}
      />
    </svg>
  );
};

export default TriangleRedExclamationIcon;
