import { SVGAttributes } from 'react';

export default function ExamDurationIcon({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={48}
        height={48}
        rx={24}
        fill="#2BD6D6"
        fillOpacity={0.2}
      />
      <path
        d="M24 9a15 15 0 100 30.001A15 15 0 0024 9z"
        fill="#2BD6D6"
      />
      <g filter="url(#filter0_d_159_6731)">
        <path
          d="M23.063 25.313l6.825 4.2 1.05-1.707-2.954-1.772-2.953-1.8v-6.797h-1.968v7.875z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_159_6731"
          x={23.0625}
          y={17.4375}
          width={8.76389}
          height={12.9639}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx={0.444444}
            dy={0.444444}
          />
          <feGaussianBlur stdDeviation={0.222222} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6731"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6731"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
