import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const I18N_DEBUG = import.meta.env.VITE_APP_I18N_DEBUG;

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    returnNull: false, // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
    debug: I18N_DEBUG === 'true',
    load: 'languageOnly', // Read 'navigator.language' as language string -> en-XX = en; default 'all'
    detection: { caches: [] }, // Disable language detection cache; default ['localStorage']
    fallbackLng: 'en',
    supportedLngs: ['it', 'en'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });

export default i18n;
