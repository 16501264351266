import { Outlet, useOutletContext } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import PageLoader from './shared/components/PageLoader/PageLoader';
import useSocket from './shared/socket/useSocket';
import { getCompanyBrandCustomizations, getLoading } from './store/exam/selectors';
import { useAppSelector } from './store/store';

type ContextType = { socket: Socket; connected: boolean };

export const useSocketInstance = () => useOutletContext<ContextType>();

const App = () => {
  // Hooks
  const { socket, connected } = useSocket();

  // Selectors
  const loading = useAppSelector(getLoading);
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  return (
    <>
      {brandCustomization && (
        <style>
          {`
          :root {
            --page-background-color: ${brandCustomization['page-background-color']};
            --accent-color: ${brandCustomization['accent-color']};
            --text-color: ${brandCustomization['text-color']};
            --btn-success-background-color: ${brandCustomization['accent-color']}
          }
          `}
        </style>
      )}
      <div className="h-full">
        {loading && <PageLoader size={64} />}
        <Outlet context={{ socket, connected }} />
      </div>
    </>
  );
};

export default App;
