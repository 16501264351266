import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props {
  version: 'mobile' | 'desktop';
}

const CLASSES = {
  mobile: 'flex w-full flex-col justify-center bg-[var(--accent-color)] p-4 lg:hidden',
  desktop:
    'hidden relative w-full flex-col translate-y-[50px] min-h-[140px] justify-start lg:rounded-2xl bg-[var(--accent-color)] p-4 shadow-card lg:flex',
};

const TestBanner = ({ version }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={CLASSES[version]}>
      <p className={clsx('text-center text-lg font-bold leading-7 text-[var(--text-color)]')}>
        {t('interviewSimulation.banner.title')}
      </p>
      <p className="text-center font-normal text-[var(--text-color)]">
        {t('interviewSimulation.banner.subtitle')}
      </p>
    </div>
  );
};

export default TestBanner;
