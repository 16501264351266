import { SVGAttributes } from 'react';

const ClockIcon = ({ ...props }: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm1-8h4v2h-6V7h2v5z"
        fill={props.color ?? '#000'}
      />
    </svg>
  );
};

export default ClockIcon;
