import { SVGAttributes } from 'react';

export default function MixedCustomQuestions({ ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="48"
        height="48"
        rx="24"
        fill="#6B52FF"
        fillOpacity="0.2"
      />
      <path
        d="M36 16.8333V31.5C36 31.9862 35.8055 32.4525 35.4593 32.7964C35.1131 33.1402 34.6435 33.3333 34.1538 33.3333H18.75L15.0485 36.5543C15.044 36.5591 15.039 36.5633 15.0335 36.5669C14.702 36.8465 14.2812 37.0001 13.8462 37C13.5753 36.9995 13.3079 36.9401 13.0627 36.8258C12.7438 36.6799 12.474 36.4458 12.2855 36.1515C12.0971 35.8573 11.9979 35.5154 12 35.1667V16.8333C12 16.3471 12.1945 15.8808 12.5408 15.537C12.887 15.1932 13.3566 15 13.8462 15H34.1538C34.6435 15 35.1131 15.1932 35.4593 15.537C35.8055 15.8808 36 16.3471 36 16.8333Z"
        fill="#6B52FF"
      />
      <g filter="url(#filter0_d_159_6732)">
        <rect
          x="21"
          y="19.7499"
          width="11.25"
          height="1.5"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_159_6732)">
        <rect
          x="21"
          y="23.4999"
          width="11.25"
          height="1.5"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_d_159_6732)">
        <rect
          x="21"
          y="27.2499"
          width="11.25"
          height="1.5"
          fill="white"
        />
      </g>
      <g filter="url(#filter3_d_159_6732)">
        <circle
          cx="18"
          cy="20.4999"
          r="1.27778"
          stroke="white"
          strokeWidth="0.444444"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter4_d_159_6732)">
        <circle
          cx="18"
          cy="24.2499"
          r="1.5"
          fill="white"
        />
      </g>
      <g filter="url(#filter5_d_159_6732)">
        <circle
          cx="18"
          cy="27.9999"
          r="1.27778"
          stroke="white"
          strokeWidth="0.444444"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_159_6732"
          x="21"
          y="19.7499"
          width="12.1389"
          height="2.38889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.444444"
            dy="0.444444"
          />
          <feGaussianBlur stdDeviation="0.222222" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_159_6732"
          x="21"
          y="23.4999"
          width="12.1389"
          height="2.38889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.444444"
            dy="0.444444"
          />
          <feGaussianBlur stdDeviation="0.222222" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_159_6732"
          x="21"
          y="27.2499"
          width="12.1389"
          height="2.38889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.444444"
            dy="0.444444"
          />
          <feGaussianBlur stdDeviation="0.222222" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_159_6732"
          x="16.5"
          y="18.9999"
          width="3.88889"
          height="3.88889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.444444"
            dy="0.444444"
          />
          <feGaussianBlur stdDeviation="0.222222" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_159_6732"
          x="16.5"
          y="22.7499"
          width="3.88889"
          height="3.88889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.444444"
            dy="0.444444"
          />
          <feGaussianBlur stdDeviation="0.222222" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_159_6732"
          x="16.5"
          y="26.4999"
          width="3.88889"
          height="3.88889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.444444"
            dy="0.444444"
          />
          <feGaussianBlur stdDeviation="0.222222" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_6732"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_6732"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
