import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../App';
import PageLoader from '../../shared/components/PageLoader/PageLoader';
import { actions as examActions } from '../../store/exam/reducer';
import { getAssessment, getCurrentStep, getLoading } from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';

const ApplicationAlias = () => {
  const { socket, connected } = useSocketInstance();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const dispatch = useAppDispatch();
  const assessment = useAppSelector(getAssessment);
  const currentStep = useAppSelector(getCurrentStep);
  const loading = useAppSelector(getLoading);

  const navigate = useNavigate();

  useEffect(() => {
    if (job_post_alias && application_alias && assessment) {
      dispatch(examActions.GET_NEXT_STEP_TO_COMPLETE());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application_alias]);

  useEffect(() => {
    if (assessment && currentStep && job_post_alias && application_alias && !loading) {
      switch (currentStep.type) {
        case 'introduction':
          navigate(`/${job_post_alias}/${assessment.application_alias}/intro`, { replace: true });
          break;
        case 'briefing':
          navigate(`/${job_post_alias}/${assessment.application_alias}/briefing`, {
            replace: true,
          });
          break;
        case 'filter-question':
          navigate(`/${job_post_alias}/${assessment.application_alias}/filter-questions`, {
            replace: true,
          });
          break;
        case 'summary':
          navigate(`/${job_post_alias}/${assessment.application_alias}/summary`, { replace: true });
          break;
        case 'video':
          navigate(`/${job_post_alias}/${assessment.application_alias}/custom-interview`, {
            replace: true,
          });
          break;
        case 'soft-skill-question':
          navigate(`/${job_post_alias}/${assessment.application_alias}/soft-skill-interview`, {
            replace: true,
          });
          break;
        case 'hard-skill-question':
          navigate(`/${job_post_alias}/${assessment.application_alias}/hard-skill-questionnaire`, {
            replace: true,
          });
          break;
        case 'custom-questionnaire-question':
          navigate(`/${job_post_alias}/${assessment.application_alias}/custom-questionnaire`, {
            replace: true,
          });
          break;
        case 'text':
        case 'rate':
        case 'file-input':
        case 'single-choice':
        case 'multiple-choice':
        case 'quiz':
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <div className="h-full">
      {loading && <PageLoader size={64} />}
      <Outlet context={{ socket, connected }} />
    </div>
  );
};

export default ApplicationAlias;
