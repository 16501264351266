import clsx from 'clsx';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import MicrophoneIcon from '../../shared/components/Icons/MicrophoneIcon';
import ErrorBox from './ErrorBox';

interface Props {
  prompting: boolean;
  hasAudioPermissions: boolean;
}

const AudioPreview = forwardRef<HTMLDivElement, Props>(
  ({ prompting, hasAudioPermissions }, ref) => {
    const { t } = useTranslation();

    return (
      <>
        <div className={clsx('mb-4 flex items-center justify-start')}>
          <MicrophoneIcon />
          <div className={clsx('ml-4 h-[8px] flex-1 overflow-hidden rounded-[20px] bg-[#E4E4E7]')}>
            <div
              ref={ref}
              className={clsx('h-full w-0 rounded-[20px] bg-[#3EAF6C] duration-[200] ease-in-out', {
                '!w-0': !hasAudioPermissions,
              })}
            />
          </div>
        </div>
        <ErrorBox
          className="mb-4"
          visible={!prompting && !hasAudioPermissions}
          message={t('askPermissions.errorBoxes.microphone')}
        />
      </>
    );
  }
);

AudioPreview.displayName = 'AudioPreview';

export default AudioPreview;
