import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
  color?: string;
}

const TriangleRedExclamationAltIcon = ({ color = '#CF222E', ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M24 7.2c1.065 0 2.048.563 2.588 1.485l16.2 27.6c.547.93.547 2.078.015 3.008A3.005 3.005 0 0 1 40.2 40.8H7.8a3.005 3.005 0 0 1-2.602-1.507 3.012 3.012 0 0 1 .015-3.008l16.2-27.6A2.994 2.994 0 0 1 24 7.2Zm0 9.6c-.997 0-1.8.803-1.8 1.8V27c0 .998.803 1.8 1.8 1.8.998 0 1.8-.802 1.8-1.8v-8.4c0-.997-.802-1.8-1.8-1.8Zm2.4 16.8a2.4 2.4 0 1 0-4.8 0 2.4 2.4 0 0 0 4.8 0Z"
      />
    </svg>
  );
};

export default TriangleRedExclamationAltIcon;
