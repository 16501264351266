import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import { SoftSkillQuestionStep } from '../../../interfaces/assessment';
import Button from '../../../shared/components/Button/Button';
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import AudioAnalyserDialog from '../../../shared/components/Dialog/contents/AudioAnalyserDialog';
import Dialog from '../../../shared/components/Dialog/Dialog';
import ConnectionIcon from '../../../shared/components/Icons/ConnectionIcon';
import EyeCloseIcon from '../../../shared/components/Icons/EyeCloseIcon';
import EyeOpenIcon from '../../../shared/components/Icons/EyeOpenIcon';
import MicrophoneIcon from '../../../shared/components/Icons/MicrophoneIcon';
import RecLittleIcon from '../../../shared/components/Icons/RecLittleIcon';
import VideoIcon from '../../../shared/components/Icons/VideoIcon';
import useAudioAnalyser from '../../../shared/hooks/useAudioAnalyser';
import { convertHexToRGBA } from '../../../shared/utils/colors';
import { getCountDown } from '../../../shared/utils/getCountDown';
import { actions as examActions } from '../../../store/exam/reducer';
import {
  getCompanyBrandCustomizations,
  getCompletedChunks,
  getCurrentSoftSkillQuestionCounters,
  getCurrentStep,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';

const DURATION: number = +import.meta.env.VITE_APP_INTERVIEW_DURATION;
const MIN_INTERVIEW_DURATION: number = +import.meta.env.VITE_APP_MIN_INTERVIEW_DURATION;
const TICK_INTERVAL = Number(import.meta.env.VITE_NO_AUDIO_CHECK_TICK_INTERVAL);
const LIMIT = Number(import.meta.env.VITE_NO_AUDIO_CHECK_LIMIT);
const THRESHOLD = Number(import.meta.env.VITE_NO_AUDIO_CHECK_THRESHOLD);

interface ChunkPayload {
  id?: number;
  type: 'start' | 'chunk' | 'end' | 'aborted';
  data?: ArrayBuffer;
  part: number;
  elapsed_time: number;
}

const VideoInterview = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  const navigate = useNavigate();
  const { startAnalyser, stopAnalyser } = useAudioAnalyser(
    TICK_INTERVAL, // tick interval (in milliseconds)
    LIMIT, // no-audio limit (in milliseconds)
    THRESHOLD, // volume threshold (volume can be between 0.xx and more)
    () => {
      // onNoAudio callback
      chunks.current = [
        {
          id: currentStep?.data.question.id,
          type: 'aborted',
          part: 0,
          elapsed_time: 0,
        },
      ];
      handleStop(); // Stop recording
      setIsDialogOpen(true); // Open dialog
    }
  );

  const { socket, connected } = useSocketInstance();

  // Selectors
  const completed_chunks = useAppSelector(getCompletedChunks);
  const currentStep = useAppSelector(getCurrentStep) as SoftSkillQuestionStep | null;
  const counters = useAppSelector(getCurrentSoftSkillQuestionCounters);

  // Refs
  const player = useRef<HTMLVideoElement | null>(null);
  const stream = useRef<MediaStream | undefined>();
  const recorder = useRef<MediaRecorder | undefined>();
  const chunkIndex = useRef<number>(0);
  const elapsed = useRef<number>(0);
  const completionPercentage = useRef<number>(10);
  const total_chunks = useRef<number>(0);
  const chunks = useRef<ChunkPayload[]>([]);

  // State
  const [height] = useState<number>(window.innerHeight);
  const [width] = useState<number>(window.innerWidth);
  const [overlay, setOverlay] = useState<boolean>(false);
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(5);
  const [recording, setRecording] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>(getCountDown(DURATION, elapsed?.current)); //This gets formatted to 03:00 string.
  const [completed, setCompleted] = useState<boolean>(false);
  const [enableStop, setEnableStop] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Selectors
  const brandCustomization = useAppSelector(getCompanyBrandCustomizations);

  // Computed
  const backgroundColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 5);
  const borderColor = convertHexToRGBA(brandCustomization?.['accent-color'] ?? '', 25);

  // Handlers
  const handleStart = () => {
    const payload: ChunkPayload = {
      id: currentStep?.data.question.id,
      type: 'start',
      part: chunkIndex.current,
      elapsed_time: elapsed.current,
    };
    chunks.current = [payload];
    chunkIndex.current = chunkIndex.current + 1;
    socket.emit('soft-skill-question-stream', payload);
  };

  const handleStop = (): void => {
    setRecording(false);
    stream.current?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    recorder.current && recorder.current.removeEventListener('dataavailable', handleDataChunk);
    recorder.current && recorder.current.removeEventListener('start', handleStart);
    recorder.current && recorder.current.removeEventListener('stop', handleStop);
    recorder.current && recorder.current.removeEventListener('error', handleError);
  };

  const stopButtonClickHandler = useCallback((): void => {
    setSubmitted(true);
    recorder.current && recorder.current.stop();
    stopAnalyser();
    setTimeout(() => {
      chunks.current = [
        ...chunks.current.sort((a: ChunkPayload, b: ChunkPayload) => a.part - b.part),
      ];
      const last_part: number =
        chunks.current.length > 0
          ? chunks.current[chunks.current.length - 1].part + 1
          : chunkIndex.current + 1;
      total_chunks.current = last_part;
      const payload: ChunkPayload = {
        id: currentStep?.data.question.id,
        type: 'end',
        part: last_part,
        elapsed_time: elapsed.current,
      };
      chunks.current = [...chunks.current, payload];
      socket.emit('soft-skill-question-stream', payload);
      setCompleted(true);
    }, 2000);
  }, [stopAnalyser, currentStep?.data.question.id, socket]);

  const handleError = (_e: unknown): void => {
    // TODO: Handler error event
  };

  const recordingHandler = async (): Promise<void> => {
    if (!recording) {
      setShowCountdown(true);
    } else {
      stopButtonClickHandler();
    }
  };

  const handleDataChunk = useCallback(
    async (e: BlobEvent) => {
      const payload: ChunkPayload = {
        id: currentStep?.data.question.id,
        type: 'chunk',
        data: await e.data.arrayBuffer(),
        part: chunkIndex.current,
        elapsed_time: elapsed.current,
      };
      chunks.current = [...chunks.current, payload].sort(
        (a: ChunkPayload, b: ChunkPayload) => a.part - b.part
      );
      chunkIndex.current = chunkIndex.current + 1;
      socket.emit('soft-skill-question-stream', payload);
    },
    [currentStep?.data.question.id, socket]
  );

  // Effects
  // Handle video and audio stream from user devices
  useEffect(() => {
    const createStream = async (): Promise<void> => {
      if (player && player.current) {
        stream.current = await navigator.mediaDevices.getUserMedia({
          video: {
            width: width > 640 ? 640 : width,
            height: width > 640 ? 360 : height,
            aspectRatio: 16 / 9,
            frameRate: { ideal: 30 },
          },
          audio: {
            sampleSize: 16,
            channelCount: 1,
          },
        });
        try {
          player.current.srcObject = stream.current;
          player.current.onloadedmetadata = () => player.current?.play();
        } catch (e) {
          // Silently suppress eventual errors on page refresh
        }
      }
    };
    createStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  // Handle recording elapsed time
  useEffect(() => {
    let recordingInterval: ReturnType<typeof setInterval> | undefined;

    if (recording) {
      recordingInterval = setInterval(() => {
        elapsed.current = elapsed.current + 1;
        setTimer(getCountDown(DURATION, elapsed.current));
      }, 1000);
    } else {
      clearInterval(recordingInterval);
    }

    return () => {
      clearInterval(recordingInterval);
    };
  }, [recording]);

  // Handle time expiration
  useEffect(() => {
    if (elapsed.current === DURATION) {
      stopButtonClickHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed.current, recorder]);

  // Handle stop recording button
  useEffect(() => {
    if (elapsed.current >= MIN_INTERVIEW_DURATION && !enableStop) {
      setEnableStop(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed.current]);

  // Handle countdown when recording button is pressed
  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;

    if (showCountdown) {
      interval = setInterval(() => {
        setCountdown((state: number) => state - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showCountdown]);

  // Handle countdown expiration
  useEffect(() => {
    if (countdown === 0 && !completed) {
      setShowCountdown(false);
      startAnalyser();
      setRecording(true);

      if (stream && stream.current) {
        recorder.current = new MediaRecorder(stream.current);
        recorder.current.addEventListener('dataavailable', handleDataChunk);
        recorder.current.addEventListener('start', handleStart);
        recorder.current.addEventListener('stop', handleStop);
        recorder.current.addEventListener('error', handleError);
        /**
         * TODO:
         *
         * The number of milliseconds to record into each Blob.
         * If this parameter isn't included, the entire media duration is recorded into a single Blob
         * unless the requestData() method is called to obtain the Blob and trigger the creation of a new Blob into which the media continues to be recorded.
         *
         * If a timeslice property was passed into the MediaRecorder.start() method that started media capture,
         * a dataavailable event is fired every timeslice milliseconds.
         * That means that each blob will have a specific time duration
         * (except the last blob, which might be shorter, since it would be whatever is left over since the last event).
         * So if the method call looked like this — recorder.start(1000); — the dataavailable event would fire after each second of media capture,
         * and our event handler would be called every second with a blob of media data that's one second long.
         * You can use timeslice alongside MediaRecorder.stop() and MediaRecorder.requestData() to produce multiple same-length blobs plus other shorter blobs as well.
         */
        recorder.current.state === 'inactive' && recorder.current.start(1000);
        dispatch(examActions.VIDEO_INTERVIEW_START());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, completed, stream, recorder]);

  useEffect(() => {
    if (total_chunks.current > 0) {
      const percentage: number =
        100 - Math.round(((total_chunks.current - completed_chunks) / total_chunks.current) * 100);
      completionPercentage.current = percentage;
      // Check if it has been completed
      if (completionPercentage.current === 100) {
        // Check whether the soft skill has a retake and user has reached limit or the soft skills has no retake. Then redirect
        if (
          (currentStep &&
            currentStep.data.retake &&
            currentStep.data.retake.current + 1 === currentStep.data.retake.limit) ||
          (currentStep && !currentStep.data.retake)
        ) {
          navigate(`/${job_post_alias}/${application_alias}/soft-skill-interview-done`, {
            replace: true,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed_chunks]);

  if (!currentStep || currentStep.type !== 'soft-skill-question') {
    return null;
  }

  if (isDialogOpen) {
    return (
      <Dialog isOpen={isDialogOpen}>
        <AudioAnalyserDialog
          onClick={() =>
            navigate(`/${job_post_alias}/${application_alias}/test-devices`, {
              replace: true,
              state: {
                from: 'soft-skill-interview',
              },
            })
          }
        />
      </Dialog>
    );
  }

  return (
    <div className="m-[auto] flex h-full w-full max-w-5xl flex-col items-center justify-center">
      {/* Connection status Banner */}
      <ConnectionStatus
        type="banner"
        connected={connected}
      />
      <div
        className={clsx(
          'm-[0 auto] relative flex h-full w-full flex-col items-center gap-6 overflow-hidden bg-white p-6',
          'lg:max-h-[576px] lg:max-w-[1024px] lg:rounded-lg lg:shadow-cust-1'
        )}
      >
        <div className="flex w-full items-center justify-between">
          {/* Question counter */}
          <div className="text-lg font-bold">
            {t('videoInterview.counter.question')} {counters && counters.current + 1}{' '}
            {t('videoInterview.counter.of')} {counters && counters.total}
          </div>
          <div className="flex items-center gap-2">
            {/* Audio, Video, Connection icons */}
            <VideoIcon color="#1F883D" />
            <MicrophoneIcon color="#1F883D" />
            <ConnectionIcon color="#1F883D" />
          </div>
        </div>
        <div
          className={clsx('relative mb-12 flex w-full rounded-lg border bg-[#FFF0E6] p-6 pb-24')}
          style={{ backgroundColor, borderColor }}
        >
          {/* Question text */}
          <div
            className={clsx(
              'max-h-52 overflow-y-auto text-base font-semibold text-[#111]',
              'lg:max-h-36 lg:text-2xl'
            )}
          >
            {currentStep.data.question.text}
          </div>
          {/* Video Mirror */}
          {/* Video overlay */}
          {overlay && (
            <div
              className={clsx(
                'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
                'h-32 w-32 rounded-full border-8 border-white bg-black bg-opacity-60 backdrop-blur-md',
                { 'z-10': overlay },
                { '-z-10': !overlay }
              )}
            ></div>
          )}
          <video
            ref={player}
            className={clsx(
              'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
              'h-32 w-32 rounded-full border-8 border-white object-cover'
            )}
            playsInline
            muted={true}
            controls={false}
          />

          {/* Overlay Button*/}
          <div
            className={clsx(
              'absolute -bottom-14 left-1/2 z-20 translate-x-8',
              'flex h-10 w-10 cursor-pointer items-center justify-center',
              'rounded-full border-2 border-white bg-[#f0f0f0] text-white backdrop-blur-lg'
            )}
            onClick={() => setOverlay((state: boolean) => !state)}
          >
            {overlay ? <EyeOpenIcon color="#000" /> : <EyeCloseIcon color="#000" />}
          </div>
        </div>
        {/* Footer */}
        <div className="absolute bottom-0 flex w-full flex-col items-center justify-center">
          {/* Retake counter with recording messages */}
          {currentStep.data.retake &&
            currentStep.data.retake.limit > 1 &&
            (recording ? (
              <span
                className={clsx(
                  'flex w-full items-center justify-center gap-2 border-b border-[#DDDDDD] pb-6 font-bold text-[#CF222E]',
                  'lg:border-none'
                )}
              >
                <RecLittleIcon color="#CF222E" />
                {/* (Minimum) Recording messages */}
                {enableStop
                  ? t('videoInterview.retake.counter.recording', {
                      attempt: currentStep.data.retake.current + 1,
                      retake: currentStep.data.retake.limit,
                    })
                  : t('videoInterview.retake.counter.min.recording', {
                      attempt: currentStep.data.retake.current + 1,
                      retake: currentStep.data.retake.limit,
                    })}
              </span>
            ) : (
              <span
                className={clsx(
                  'w-full border-b border-[#DDDDDD] pb-6 text-center font-bold text-[#666666]',
                  'lg:border-none'
                )}
              >
                {t('interviewSimulation.maxTime.message')}{' '}
                {t('videoInterview.retake.counter', {
                  attempt: currentStep.data.retake.current + 1,
                  retake: currentStep.data.retake.limit,
                })}
              </span>
            ))}
          {/* Recording messages */}
          {currentStep.data.retake && currentStep.data.retake.limit <= 1 && recording && (
            <span className="flex items-center gap-2 font-bold text-[#CF222E]">
              <RecLittleIcon color="#CF222E" />
              {enableStop ? t('videoInterview.recording') : t('videoInterview.min.recording')}
            </span>
          )}

          {/* Rec Button */}
          <Button
            className={clsx(
              'default my-6 flex gap-2'
              // TODO: I think this two classes are not necessary, but I will leave them here as a reminder for now
              // { success: !completed && recording && enableStop }
              // { transparent: !completed && recording && !enableStop }
            )}
            disabled={completed || (!completed && recording && !enableStop)}
            onClick={
              (!completed && !recording) || (!completed && recording && enableStop)
                ? recordingHandler
                : () => null
            }
          >
            {/* Rec Button text */}
            {!completed && !recording && t('videoInterview.button.record')}
            {!completed && recording && !enableStop && t('videoInterview.button.min.record')}
            {!completed && recording && enableStop && t('videoInterview.button.recording')}
            {/* Rec timer */}
            {recording && <div className="translate-y-[1px] font-mono">{timer}</div>}
          </Button>
        </div>

        <div className="flex items-center justify-center">
          {/* Upload Overlay */}
          <div
            className={clsx(
              'pointer-events-none absolute left-0 top-0 z-[100] flex h-full w-full flex-col items-center justify-center gap-5 bg-white',
              { hidden: !submitted }
            )}
          >
            {/* Upload disclaimer text */}
            <div className="flex flex-col items-center gap-2">
              <div className="text-lg font-semibold text-[#111]">
                {t('videoInterview.upload.disclaimer.title')}
              </div>
              <div className="text-[#626262]">{t('videoInterview.upload.disclaimer.subtitle')}</div>
            </div>
            {/* Upload Bar */}
            <div className="flex w-full max-w-[256px] items-center gap-2">
              <div className="h-2 w-full overflow-hidden rounded-full bg-[#e2e2e2]">
                <div
                  style={{ width: `${completionPercentage.current}%` }}
                  className={clsx('h-full max-w-full rounded-full transition-all ease-linear', {
                    'bg-gradient-to-r from-[#896efd] to-[#4f28f8]':
                      !brandCustomization?.['accent-color'],
                    'bg-[var(--accent-color)]': brandCustomization?.['accent-color'],
                  })}
                />
              </div>
              {/* Upload percentage text */}
              <span className="text-xs text-[#666666]">{`${completionPercentage.current}%`}</span>
            </div>
            <div className="text-[#626262]">{t('videoInterview.upload.loader')}</div>
          </div>
        </div>
        {/* Countdown overlay */}
        <div
          className={clsx(
            'absolute left-0 top-0 z-30 flex h-full w-full flex-col items-center justify-center bg-white bg-opacity-80 p-4 backdrop-blur-md',
            'lg:justify-center',
            { hidden: !showCountdown }
          )}
        >
          {/* Countdown */}
          <div
            className={clsx(
              'flex h-12 w-12 items-center justify-center rounded-full text-3xl font-extrabold backdrop-blur-lg',
              {
                'bg-[#ECECFE] text-[#111]': !brandCustomization?.['accent-color'],
                'bg-[var(--accent-color)] text-[var(--text-color)]':
                  brandCustomization?.['accent-color'],
              }
            )}
          >
            {countdown}
          </div>
          {/* Countdown Title */}
          <div className="my-2 text-center text-2xl font-bold text-[#111]">
            {t('videoInterview.title')}
          </div>

          {/* Countdown Subtitle */}
          <div className="text-center font-semibold text-[#626262]">
            {t('videoInterview.subtitle')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoInterview;
